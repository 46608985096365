const isConsecutiveDigits = (input: string): boolean => '0123456789'.includes(input) || '9876543210'.includes(input);

function validatePin(pin: string): boolean {
  if (pin.length !== 4 || !/^\d+$/.test(pin)) {
    return false;
  }

  if (pin.split('').every(letter => letter === pin[0])) {
    return false;
  }

  return [pin.substring(0, 3), pin.substring(1, 4)].every(seq => !isConsecutiveDigits(seq));
}
export default validatePin;
