import '@fontsource/manrope';
import '@fontsource/nunito-sans';

import { ThemeProvider } from '@material-ui/core/styles';
import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { EmptyAction } from 'typesafe-actions';

import { trackPageview } from '../../ducks/analytics';
import { clearErrorMessage } from '../../ducks/application';
import useHandlePageview from '../../hooks/useHandlePageview';
import Banner from '../LandingPage/Banner';
import Cards from '../LandingPage/Cards';
import CreditBanner from '../LandingPage/CreditBanner';
import CreditChecks from '../LandingPage/CreditChecks/CreditChecks';
import EasyCredit from '../LandingPage/EasyCredit';
import Everywhere from '../LandingPage/Everywhere/Everywhere';
import Footer from '../LandingPage/Footer';
import Header from '../LandingPage/Header';
import HomeCarousel from '../LandingPage/HomeCarousel';
import PreFooter from '../LandingPage/PreFooter';
import VipInfo from '../LandingPage/VipInfo/VipInfo';
import Home from './img/home1.png';
import { theme } from './NeloBrandWrapper';

const StyledBackground = styled.div`
  background: linear-gradient(178deg, #107ef2 1.86%, #c2a0e7 105.99%);

  @media screen and (min-width: 640px) {
    background-image: linear-gradient(15deg, #b4d0eb, #2b8def, #4596e8, #f8aee1, #b4d0eb);
    background-size: 400% 400%;
    animation: gradient 10s ease infinite;
  }

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

interface NeloBrandWrapperProps {
  title: string;
  subtitle?: string;
  secondarySubtitle?: string | null;
  children?: ReactElement | ReactElement[];
  background: 'completed' | 'landing' | 'none';
  onClick?: () => void;
  footerColor?: string;
  approved?: boolean;
}

interface DispatchProps {
  clearErrorMessage: () => void;
  trackPageview: (path: string) => void;
}

function NeloBrandLandingPage(props: NeloBrandWrapperProps & DispatchProps): ReactElement {
  useHandlePageview(props.clearErrorMessage, props.trackPageview);

  return (
    <ThemeProvider theme={theme}>
      <StyledBackground>
        <Header />
        <div>
          <Banner />
        </div>
      </StyledBackground>
      <CreditChecks />
      <CreditBanner />
      <Cards />
      <EasyCredit />
      <div className="relative mx-4 max-w-[1200px] xl:m-auto rounded-3xl">
        <img src={Home} className="rounded-3xl transition-opacity" alt="Imagen rotativa" />
      </div>
      <HomeCarousel />
      <VipInfo />
      <Everywhere />
      <PreFooter
        text="Sin rodeos ni letras chiquitas. Somos el crédito para ti."
        buttonLabel="CREA TU CUENTA"
        href="/signup/phone-email"
      />
      <Footer />
    </ThemeProvider>
  );
}

const mapDispatchToProps = {
  clearErrorMessage: (): EmptyAction<string> => clearErrorMessage(),
  trackPageview: (path: string): EmptyAction<string> => trackPageview(path)
};

export default connect(null, mapDispatchToProps)(NeloBrandLandingPage);
