import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { createTransform, persistReducer, persistStore, Transform } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import createSagaMiddleware from 'redux-saga';
import { all, call, SagaGenerator } from 'typed-redux-saga';

import NeloApiClient from './clients/NeloApiClient';
import { analyticsSaga } from './ducks/analytics';
import application, {
  applicationSaga,
  ApplicationState,
  initialState as applicationInitialState
} from './ducks/application';
import auth, { AuthState, initialState as authInitialState } from './ducks/auth';
import { serverDrivenCtaSaga } from './ducks/serverDrivenCta';
import signup, { initialState as signupInitialState, signupSaga, SignupState } from './ducks/signup';
import verificationCode, {
  initialState as verificationCodeInitialState,
  MobileVerificationState,
  verificationCodeSaga
} from './ducks/verificationCode';

function* rootSaga(): SagaGenerator<void> {
  yield* all([
    call(serverDrivenCtaSaga),
    call(verificationCodeSaga),
    call(analyticsSaga),
    call(signupSaga),
    call(applicationSaga)
  ]);
}

type ReducerState = ApplicationState | MobileVerificationState | AuthState | SignupState;

const initialState: Record<string, ReducerState> = {
  application: applicationInitialState,
  verificationCode: verificationCodeInitialState,
  auth: authInitialState,
  signup: signupInitialState
};

const expireReducer = (
  expireIn: number,
  initialState: Record<string, ReducerState>
): Transform<ReducerState, ReducerState> => {
  const expireAtKey = 'expireAt';
  const storedExpiration = localStorage.getItem(expireAtKey);
  let expired = false;

  if (storedExpiration) {
    const expiring = parseInt(storedExpiration);
    const now = new Date().getTime();
    expired = !isNaN(expiring) && now > expiring;
  }

  return createTransform(
    (inboundState: ReducerState): ReducerState => {
      const expireValue = (new Date().getTime() + expireIn).toString();
      localStorage.setItem(expireAtKey, expireValue);

      return inboundState;
    },
    (outboundState: ReducerState, key: string | number | symbol): ReducerState => {
      return expired ? initialState[key as string] : outboundState;
    }
  );
};

const rootReducer = combineReducers({
  application,
  verificationCode,
  signup,
  auth
});

const SIX_HOURS_IN_MS = 6 * 60 * 60 * 1000;
const persistConfig = {
  key: 'root',
  storage,
  transforms: [expireReducer(SIX_HOURS_IN_MS, initialState)]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  preloadedState: initialState,
  reducer: persistedReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(sagaMiddleware),
  devTools: process.env.NODE_ENV !== 'production'
});

// const store = createStore(persistedReducer, applyMiddleware(sagaMiddleware));
export const persistor = persistStore(store);

// Register store with NeloApiClient so it can retrieve the auth token
NeloApiClient.registerStore.bind(NeloApiClient, store)();

sagaMiddleware.run(rootSaga);

export default store;
