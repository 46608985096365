import 'firebase/analytics';

import firebase from 'firebase/app';
import { all, call, put, SagaGenerator, select, takeEvery } from 'typed-redux-saga';
import { createAction, PayloadAction } from 'typesafe-actions';

import packageJson from '../../package.json';
import { AnalyticEvent } from '../analytics/AnalyticEvent';
import { AuthState, updateAuthState } from './auth';

interface CopyPasteProps {
  fieldName: string;
  path: string;
}

export const emitAnalyticEventAction = createAction('analytics/EMIT_ANALYTIC_EVENT')<AnalyticEvent>();
export const updateAppStarted = createAction('analytics/APP_STARTED')();
export const trackPageview = createAction('analytics/TRACK_PAGEVIEW')<string>();
export const trackCopyPaste = createAction('analytics/TRACK_COPYPASTE')<CopyPasteProps>();

// These keys are not secret. See https://stackoverflow.com/a/37484053
const firebaseConfig = {
  apiKey: 'AIzaSyASs5MDHpCjyQ2mOIwI8zONO95v97ApKZY',
  authDomain: 'nelo-mobile.firebaseapp.com',
  databaseURL: 'https://nelo-mobile.firebaseio.com',
  projectId: 'nelo-mobile',
  storageBucket: 'nelo-mobile.appspot.com',
  messagingSenderId: '216800178534',
  appId: '1:216800178534:web:5d78b72b1b9e6b288e18a7',
  measurementId: 'G-Y84SQVK54Q'
};

firebase.initializeApp(firebaseConfig);

function getAnalyticsUserProperties({ auth }: { auth: AuthState }): { [key: string]: string | null } {
  return {
    uuid: auth.userUuid,
    is_admin: String(auth.authAction?.includes('ADMIN')),
    version: packageJson.version
  };
}

function* setFirebasePageview(action: PayloadAction<string, string>): SagaGenerator<void> {
  yield* call(firebase.analytics().logEvent.bind(firebase.analytics()), 'web_signup_page_view', {
    page_path: action.payload
  });
  // yield* call(firebase.analytics().setCurrentScreen.bind(firebase.analytics()), action.payload);
}

function* setTrackCopyPaste(action: PayloadAction<string, CopyPasteProps>): SagaGenerator<void> {
  yield* put(
    emitAnalyticEventAction({
      name: 'WEB_SIGNUP_COPY_PASTE',
      params: { fieldName: action.payload.fieldName, path: action.payload.path }
    })
  );
}

export function* emitAnalyticEvent(action: PayloadAction<string, AnalyticEvent>): SagaGenerator<void> {
  const name = action.payload.name.toLocaleLowerCase();
  const params = action.payload.params;
  yield* call(firebase.analytics().logEvent.bind(firebase.analytics()), name, params);
}

function* setFirebaseUserProperties(): SagaGenerator<void> {
  const userProperties: { [key: string]: string | null } = yield* select(getAnalyticsUserProperties);
  const uuid = userProperties.uuid;
  yield* call(firebase.analytics().setUserProperties.bind(firebase.analytics()), userProperties);
  if (uuid) {
    yield* call(firebase.analytics().setUserId.bind(firebase.analytics()), uuid);
  }
}

export function* analyticsSaga(): SagaGenerator<void> {
  yield* all([
    takeEvery(updateAppStarted, setFirebaseUserProperties),
    takeEvery(updateAuthState, setFirebaseUserProperties),
    takeEvery(trackPageview, setFirebasePageview),
    takeEvery(trackCopyPaste, setTrackCopyPaste),
    takeEvery(emitAnalyticEventAction, emitAnalyticEvent)
  ]);
}
