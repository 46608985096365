import Adiddas from '../../Nelo/img/Adidas-website-logo.png';
import Amazon from '../../Nelo/img/Amazon-website-logo.png';
import At from '../../Nelo/img/AT-website-logo.png';
import Bodega from '../../Nelo/img/bodega-aurrera-logo.png';
import CFE from '../../Nelo/img/CFE-website-logo.png';
import Coppel from '../../Nelo/img/Coppel-website-logo.png';
import Cp from '../../Nelo/img/CP-website-logo.png';
import Hm from '../../Nelo/img/HM-website-logo.png';
import Izzi from '../../Nelo/img/izzi.png';
import Liverpool from '../../Nelo/img/Liverpool-website-logo.png';
import Nike from '../../Nelo/img/Nike-website-logo.png';
import Sams from '../../Nelo/img/Sams-website-logo.png';
import Shein from '../../Nelo/img/Shein-website-logo.png';
import Telcel from '../../Nelo/img/Telcel-website-logo.png';
import Telmex from '../../Nelo/img/telmex-logo.png';
import Tp from '../../Nelo/img/TP-website-logo.png';
import WallmartImage from '../../Nelo/img/www-nelo-mx-stores-walmart.png';
import Zara from '../../Nelo/img/Zara-website-logo.png';

const images = [
  {
    name: 'Walmart',
    image: WallmartImage
  },
  {
    name: 'CFE',
    image: CFE
  },
  {
    name: 'Amazon',
    image: Amazon
  },
  {
    name: 'Bodega Aurera',
    image: Bodega
  },
  {
    name: 'SHEIN',
    image: Shein
  },
  {
    name: 'Coppel',
    image: Coppel
  },
  {
    name: 'Liverpool',
    image: Liverpool
  },
  {
    name: 'Telmex',
    image: Telmex
  },
  {
    name: 'Cinépolis',
    image: Cp
  },
  {
    name: 'AT&T',
    image: At
  },
  {
    name: 'Zara',
    image: Zara
  },
  {
    name: 'Totalplay',
    image: Tp
  },
  {
    name: 'Telcel',
    image: Telcel
  },
  {
    name: 'Nike',
    image: Nike
  },
  {
    name: 'Izzi',
    image: Izzi
  },
  {
    name: 'Adidas',
    image: Adiddas,
    border: true
  },
  {
    name: 'Sams club',
    image: Sams
  },
  {
    name: 'H&M',
    image: Hm,
    border: true
  }
];

const Everywhere = (): JSX.Element => {
  return (
    <div className="ctn mt-16 md:mt-32">
      <p className="text-center text-darkGrey tracking-[3px] uppecase mb-8 sm:mb-10">CRÉDITO EN TODAS PARTES</p>
      <p className="text-center font-light tracking-[-0.44px] text-[42px] leading-[50px] md:text-[60px] md:leading-[72px]">
        Compra en <span className="text-lightBlue font-medium">todas</span>
        <br /> las tiendas a quincenas
      </p>
      <div className="grid grid-cols-6 gap-4 md:gap-8 mt-10 md:mt-24">
        {images.map((item, index) => (
          <div key={index}>
            <img
              className={`md:hover:scale-[1.1] transition-all ${
                item.border !== undefined ? 'border md:border-2 border-gray-400 rounded-md md:rounded-[14px]' : ''
              }`}
              src={item.image}
              alt={`Image ${index + 1}`}
              width={140}
              height={140}
            />
            <p className="text-center font-nunito mt-2 md:mt-4 text-[10px] md:text-[14px] xl:text-[20px] tracking-[-0.2px] text-darkGrey font-light leading-[16px] xl:leading-[34px]">
              {item.name}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Everywhere;
