import './App.css';
import './localization/i18n';

import { datadogRum } from '@datadog/browser-rum';
import StyledEngineProvider from '@material-ui/core/StyledEngineProvider';
import React, { ReactElement, useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useTranslation } from 'react-i18next';
import { Provider, useDispatch } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import AddressEntryV2 from './components/AddressEntry';
import ApplicationCompletedAlternate from './components/ApplicationCompleted';
import ApplicationCompletedExistingUser from './components/ApplicationCompletedExistingUser';
import ApplicationErrorWrapper from './components/ApplicationErrorWrapper';
import CurpConfirm from './components/CurpConfirm';
import CurpGenerator from './components/CurpGenerator';
import ErrorPage from './components/ErrorPage';
import FourOhFour from './components/FourOhFour';
import LandingPage from './components/LandingPage';
import MobileVerificationEntry from './components/MobileVerificationEntry';
import OccupationAndIncome from './components/OccupationAndIncome';
import PhoneAndEmailEntry from './components/PhoneAndEmailEntry';
import PinEntry from './components/PinEntry';
import PinVerify from './components/PinVerify';
import { emitAnalyticEventAction, updateAppStarted } from './ducks/analytics';
import store, { persistor } from './Store';
import UseSift from './util/useSift';

export interface Path {
  /**
   * A URL pathname, beginning with a /.
   */
  pathname: string;

  /**
   * A URL search string, beginning with a ?.
   */
  search: string;

  /**
   * A URL fragment identifier, beginning with a #.
   */
  hash: string;
}

type To = string | Partial<Path>;

export interface InterfaceSteps {
  path: string;
  postPath: string | null;
  previousPath: string | null;
  title: string;
  subtitle?: string;
  description?: string;
  step: number;
  fields: string[];
  validation: any[];
}

function App(): ReactElement {
  const [datadogInit, setDatadogInit] = useState<boolean>(false);
  const { t } = useTranslation();

  const tagManagerArgs = {
    gtmId: 'GTM-WVKWMWC'
  };

  useEffect(() => {
    document.title = t('app.title');
    store.dispatch(updateAppStarted());
    setDatadogInit(true);
    TagManager.initialize(tagManagerArgs);
  }, []);

  if (!datadogInit) {
    datadogRum.init({
      applicationId: '1104a3b2-5eb8-41a9-bcc9-af6c7ef21958',
      clientToken: 'pub3800a34b0d02aef68a59253b4470cbec',
      site: 'datadoghq.com',
      service: 'web-signup',
      env: 'prod',
      sampleRate: 100,
      trackInteractions: false
    });
  }

  const UnknownRouteHandler = ({ to }: { to: To }): ReactElement => {
    const prevRoute = useLocation();
    const dispatch = useDispatch();
    dispatch(
      emitAnalyticEventAction({
        name: 'WEB_SIGNUP_ERROR_PAGE',
        params: {
          previousPath: prevRoute.pathname
        }
      })
    );
    return <Navigate to={to} state={{ prevRoute }} replace />;
  };

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <StyledEngineProvider injectFirst>
          <BrowserRouter>
            <ApplicationErrorWrapper>
              <UseSift>
                <Routes>
                  <Route index element={<LandingPage />} />
                  <Route path="/signup/phone-email" element={<PhoneAndEmailEntry />} />
                  <Route path="/signup/name-email" element={<Navigate to="/signup/phone-email" replace />} />
                  <Route path="/signup/name" element={<Navigate to="/signup/phone-email" replace />} />
                  <Route path="/signup/confirm-code" element={<MobileVerificationEntry />} />
                  <Route path="/signup/curp-generator" element={<CurpGenerator />} />
                  <Route path="/signup/confirm-curp" element={<CurpConfirm />} />
                  <Route path="/signup/occupation-and-income" element={<OccupationAndIncome />} />
                  <Route path="/signup/address-entry" element={<AddressEntryV2 />} />
                  <Route path="/signup/pin" element={<PinEntry />} />
                  <Route path="/signup/pin-verify" element={<PinVerify />} />
                  <Route path="/signup/completed" element={<ApplicationCompletedAlternate />} />
                  <Route path="/signup/rejected" element={<ApplicationCompletedExistingUser />} />
                  <Route path="/signup/welcome-back" element={<ApplicationCompletedExistingUser />} />
                  <Route path="/error" element={<ErrorPage />} />
                  <Route path="/404" element={<FourOhFour />} />
                  <Route path="/v2/*" element={<Navigate to="/" replace />} />
                  <Route path="/v3/*" element={<Navigate to="/" replace />} />
                  <Route path="*" element={<UnknownRouteHandler to="/404" />} />
                </Routes>
              </UseSift>
            </ApplicationErrorWrapper>
          </BrowserRouter>
        </StyledEngineProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
