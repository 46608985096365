import { ButtonHTMLAttributes } from 'react';
import { useSwiper } from 'swiper/react';

import Arrow from './arrow';

interface ControlButtonI extends ButtonHTMLAttributes<HTMLButtonElement> {
  color?: string;
  right?: boolean;
  className?: string;
}

const ControlButton = ({ right = false, className }: ControlButtonI): JSX.Element => {
  const direction = right ? 'rotate-180' : '';
  const tailwindClass = `hidden md:grid bg-white rounded-full w-14 h-14 place-items-center shrink-0 ${direction}`;
  const swiper = useSwiper();

  return (
    <button
      className={`${tailwindClass} ${className}`}
      onClick={(): void => {
        if (right) {
          swiper.slideNext();
        } else {
          swiper.slidePrev();
        }
      }}
    >
      <Arrow className="fill-primary" />
    </button>
  );
};

export default ControlButton;
