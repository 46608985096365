import '../localization/i18n';

import { makeStyles } from '@material-ui/core/styles';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { spacing } from '../styling';
import Nelo from './Nelo';

export const useStyles = makeStyles({
  button: {
    marginTop: spacing.spacing3x
  }
});

function FourOhFour(): ReactElement {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Nelo.BrandWrapper title={t('signup.404.title')} subtitle={t('signup.404.subtitle')} hideProgressBar hideBackButton>
      <Nelo.Button
        text={t('signup.404.buttonText')}
        className={classes.button}
        onClick={(): void => {
          window.location.href = '/signup/name';
        }}
      />
    </Nelo.BrandWrapper>
  );
}

export default FourOhFour;
