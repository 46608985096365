import '../localization/i18n';

import { makeStyles } from '@material-ui/core/styles';
import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { EmptyAction, PayloadAction } from 'typesafe-actions';

import { AnalyticEvent } from '../analytics/AnalyticEvent';
import { emitAnalyticEventAction } from '../ducks/analytics';
import { ApplicationState, clearErrorMessage } from '../ducks/application';
import { SignupState, submitPersonalInfo, updateCurp } from '../ducks/signup';
import { updateOccupation } from '../ducks/signup';
import { ValidateCurpResponse } from '../interfaces/nelo-api/ValidateCurp';
import { isEmpty } from '../util/isEmpty';
import Nelo from './Nelo';

interface DispatchProps {
  updateOccupation: (occupation: string) => void;
  updateCurp: (curp: string) => void;
  submitPersonalInfo: () => void;
  emitEvent: (props: AnalyticEvent) => void;
}

interface StateProps {
  curp: string;
  isLoading: boolean;
  validateCurpResponse: ValidateCurpResponse | null;
}

export const useStyles = makeStyles({
  curpIcon: {
    marginLeft: 5,
    verticalAlign: 'middle'
  }
});

function OccupationEntry(props: DispatchProps & StateProps): ReactElement {
  const navigate = useNavigate();
  const classes = Nelo.useStyles();
  const { t } = useTranslation();
  const [submitted, setSubmitted] = useState<boolean>(false);

  useEffect(() => {
    if (props.validateCurpResponse && submitted) {
      props.emitEvent({ name: 'WEB_SIGNUP_VERIFY_CURP', params: { verify: 'true' } });
      navigate('/signup/occupation-and-income');
    }
  }, [props.validateCurpResponse]);

  return (
    <Nelo.BrandWrapper title={t('signup.curp.title')} subtitle={t('signup.curp.subtitle')} progress={3}>
      <Nelo.TextField
        name="Curp"
        className={classes.textEntry}
        label={<span>{t('signup.curp.curp.label')}</span>}
        onChange={(e: React.ChangeEvent<HTMLInputElement>): void => props.updateCurp(e.target.value)}
        value={props.curp}
        valid={!isEmpty(props.curp) && props.curp.length === 18}
      />
      <Nelo.Button
        isLoading={props.isLoading}
        text={t('signup.curp.buttonText')}
        onClick={(): false | void => {
          clearErrorMessage();
          setSubmitted(true);
          props.submitPersonalInfo();
        }}
      />
    </Nelo.BrandWrapper>
  );
}

const mapDispatchToProps = {
  updateOccupation: (occupation: string): PayloadAction<string, string> => updateOccupation(occupation),
  updateCurp: (curp: string): PayloadAction<string, string> => updateCurp(curp),
  submitPersonalInfo: (): EmptyAction<'signup/SUBMIT_PERSONAL_INFO'> => submitPersonalInfo(),
  emitEvent: (props: AnalyticEvent): any => emitAnalyticEventAction(props)
};

const mapStateToProps = ({
  signup,
  application
}: {
  signup: SignupState;
  application: ApplicationState;
}): StateProps => ({
  curp: signup.curp,
  isLoading: application.isLoading && !application.errorMessage,
  validateCurpResponse: signup.validateCurpResponse
});

export default connect(mapStateToProps, mapDispatchToProps)(OccupationEntry);
