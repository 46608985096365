import { useEffect, useState } from 'react';

interface MobileDetectProps {
  isMobile: boolean | null;
  isDesktop: boolean | null;
}

const getMobileDetect = (userAgent: string): MobileDetectProps => {
  const isAndroid = (): boolean => Boolean(userAgent.match(/Android/i));
  const isIos = (): boolean => Boolean(userAgent.match(/iPhone|iPad|iPod/i));
  const isOpera = (): boolean => Boolean(userAgent.match(/Opera Mini/i));
  const isWindows = (): boolean => Boolean(userAgent.match(/IEMobile/i));
  const isSSR = (): boolean => Boolean(userAgent.match(/SSR/i));

  const [isMobile, setIsMobile] = useState<boolean | null>(null);
  const [isDesktop, setIsDesktop] = useState<boolean | null>(null);
  Boolean(!isMobile && !isSSR());

  const width = window.innerWidth;
  const height = window.innerHeight;

  useEffect(() => {
    if (!isMobile && (isAndroid() || isIos() || isOpera() || isWindows())) {
      setIsMobile(true);
      setIsDesktop(false);
    }
    if (!isDesktop && Boolean(!isMobile && !isSSR())) {
      setIsDesktop(true);
      setIsMobile(false);
    }
    if (width <= 600 || (width <= 800 && height <= 800)) {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, [isMobile, isDesktop, isAndroid, isIos, isOpera, isWindows, isSSR, width, height]);
  return {
    isMobile,
    isDesktop
  };
};

const useMobileDetect = (): MobileDetectProps => {
  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;
  return getMobileDetect(userAgent);
};

export default useMobileDetect;
