import '../localization/i18n';

import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import useTrackData from '../hooks/useTrackData';
import { colors, spacing } from '../styling';
import Nelo from './Nelo';
import DownloadLink from './Nelo/DownloadLink';

export const useStyles = makeStyles({
  qrContainer: {
    [Nelo.theme.breakpoints.down('md')]: {
      textAlign: 'center',
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '95%'
    }
  },
  subtitle: {
    margin: spacing.spacing2x,
    color: colors.white,
    display: 'none',
    [Nelo.theme.breakpoints.down('md')]: {
      display: 'block'
    }
  }
});

function ApplicationCompleted(): ReactElement {
  const { t } = useTranslation();
  const classes = useStyles();

  useTrackData('new_user_approved');

  const titleString = t('signup.completed.downloadToFinish');

  let coloredTitle = titleString.replace(/descargar/g, '<span style="color:#E1FF58">descargar</span>');
  coloredTitle = coloredTitle.replace(/downloading/g, '<span style="color:#E1FF58">downloading</span>');
  coloredTitle = coloredTitle.replace(/Download/g, '<span style="color:#E1FF58">Download</span>');
  coloredTitle = coloredTitle.replace(/the/g, '<span style="color:#E1FF58">the</span>');
  coloredTitle = coloredTitle.replace('la', '<span style="color:#E1FF58">la</span>');
  coloredTitle = coloredTitle.replace(/app /g, '<span style="color:#E1FF58">app </span>');
  coloredTitle = coloredTitle.replace(/app\./g, '<span style="color:#E1FF58">app.</span>');
  coloredTitle = coloredTitle.replace(/Descarga/g, '<span style="color:#E1FF58">Descarga</span>');

  return (
    <Nelo.BrandCompletionPage title={coloredTitle} subtitle={null}>
      <Typography className={classes.subtitle}>{t('signup.completed.subtitleValueProps')}</Typography>
      <Grid className={classes.qrContainer}>
        <DownloadLink />
      </Grid>
    </Nelo.BrandCompletionPage>
  );
}

export default ApplicationCompleted;
