import '@fontsource/manrope';
import '@fontsource/nunito-sans';

import Grid from '@material-ui/core/Grid';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { css } from 'styled-components';
import { EmptyAction } from 'typesafe-actions';

import { trackPageview } from '../../ducks/analytics';
import { ApplicationState, clearErrorMessage } from '../../ducks/application';
import useHandlePageview from '../../hooks/useHandlePageview';
import { colors, spacing } from '../../styling';
import NeloBrandHeader from './NeloBrandHeader';
import { theme } from './NeloBrandWrapper';
import NeloProgressBar from './NeloProgressBar';

const load = css`
  0% {
    mask-position: 0% 0%, 50% 0%, 100% 0%;
  } /* all of them at the top */
  16.67% {
    mask-position: 0% 100%, 50% 0%, 100% 0%;
  }
  33.33% {
    mask-position: 0% 100%, 50% 100%, 100% 0%;
  }
  50% {
    mask-position: 0% 100%, 50% 100%, 100% 100%;
  } /* all of them at the bottom */
  66.67% {
    mask-position: 0% 0%, 50% 100%, 100% 100%;
  }
  83.33% {
    mask-position: 0% 0%, 50% 0%, 100% 100%;
  }
  100% {
    mask-position: 0% 0%, 50% 0%, 100% 0%;
  } /* all of them at the top */
`;

export const useStyles = makeStyles({
  loader: {
    width: 180,
    aspectRation: '8/5',
    animation: `${load} 2s infinite`
  },
  greenTextContainer: {
    marginTop: spacing.spacing4x,
    [theme.breakpoints.down('md')]: {
      marginTop: spacing.spacing2x
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: spacing.spacing1x
    }
  },
  progressContainer: {},
  error: {
    color: '#FF4344',
    fontWeight: 600,
    fontSize: spacing.spacing2x,
    marginBottom: spacing.spacing2x,
    [theme.breakpoints.down('md')]: {
      textAlign: 'center'
    }
  },
  adornmentIcon: {
    display: 'grid',
    justifyContent: 'center',
    alignItems: 'center'
  },
  greenText: {
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: spacing.spacing4x,
    paddingBottom: spacing.spacing4x,
    paddingRight: spacing.spacing8x,
    paddingLeft: spacing.spacing8x,
    marginBottom: 0,
    minHeight: 250,
    maxHeight: 400,
    borderRadius: 40,
    color: colors.neloBlue,
    backgroundColor: colors.neloGreen,
    fontFamily: 'Manrope',
    fontWeight: 700,
    fontSize: spacing.spacing4x,
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      borderRadius: 0,
      fontSize: spacing.spacing3x,
      paddingLeft: spacing.spacing2x,
      paddingRight: spacing.spacing2x,
      paddingTop: spacing.spacing2x,
      paddingBottom: spacing.spacing2x
    },
    [theme.breakpoints.down('sm')]: {}
  },
  formControl: {
    marginTop: spacing.spacing1x,
    marginBottom: spacing.spacing1x,
    minWidth: 120,
    [theme.breakpoints.down('sm')]: {
      marginTop: spacing.spacingHalfx,
      marginBottom: spacing.spacingHalfx
    }
  },
  smallTextEntry: {
    width: '48%'
  },
  addressTextEntry: {
    border: 'none !important'
  },
  textEntry: {
    marginBottom: spacing.spacing2x,
    background: colors.white,
    border: `1px solid rgba(170, 170, 170, 0.25)`,
    borderShadow: 'none',
    borderRadius: spacing.spacing1x,
    '& .MuiInput-underline:after': {
      border: `3px solid ${colors.neloBlue}`
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'none'
      },
      '&:hover fieldset': {
        border: `2.5px solid ${colors.neloBlue}`
      },
      '&.Mui-focused fieldset': {
        border: `2.5px solid ${colors.neloBlue}`
      }
    }
  },
  bannerContainer: {
    marginTop: 34,
    marginBottom: spacing.spacing2x,
    width: '100%',
    height: '62px',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      marginBottom: 0,
      paddingTop: spacing.spacing1x
    },
    [theme.breakpoints.down('sm')]: {
      height: 60,
      marginTop: spacing.spacing1x,
      marginBottom: 0
    }
  },
  mainContainer: {
    marginTop: spacing.spacing4x,
    backgroundColor: colors.neloBlue,
    height: `calc(96vh - 40px)`,
    [theme.breakpoints.down('md')]: {
      height: '100%',
      backgroundColor: colors.white,
      marginTop: 0
    }
  },
  contentContainer: {
    display: 'grid',
    alignItems: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 580,
    justifyContent: 'center',
    marginBottom: spacing.spacing2x,
    marginTop: spacing.spacing6x,
    borderRadius: 40,
    boxShadow: `0px 4px 16px rgba(0, 0, 0, 0.16)`,
    padding: 0,
    backgroundColor: '#FFFFFF',
    [theme.breakpoints.down('md')]: {
      marginTop: spacing.spacing2x,
      borderRadius: 0,
      boxShadow: 'none',
      marginBottom: 0,
      padding: 0
    }
  },
  loadingIcon: {
    margin: 0,
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  icon: {
    marginBottom: spacing.spacing2x,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 0
    }
  },
  title: {
    fontWeight: 800,
    fontFamily: 'Manrope',
    fontSize: spacing.spacing6x,
    alignItems: 'center',
    color: colors.neloBlue,
    marginBottom: spacing.spacing2x,
    [theme.breakpoints.down('md')]: {
      maxWidth: '95%',
      marginLeft: 'auto',
      marginRight: 'auto',
      textAlign: 'center',
      fontSize: spacing.spacing4x,
      marginBottom: spacing.spacing3x
    }
  },
  subtitle: {
    marginBottom: spacing.spacing2x,
    fontFamily: 'Nunito Sans',
    fontSize: 18,
    color: colors.secondaryAccent,
    [theme.breakpoints.down('md')]: {
      maxWidth: '95%',
      marginLeft: 'auto',
      marginRight: 'auto',
      textAlign: 'center',
      fontSize: spacing.spacing2x
    }
  },
  description: {
    marginBottom: spacing.spacing2x,
    fontFamily: 'Nunito Sans',
    fontSize: spacing.spacing2x,
    color: colors.secondaryAccent,
    [theme.breakpoints.down('sm')]: {
      marginBottom: spacing.spacing1x
    }
  },
  contentText: {
    paddingLeft: 77,
    paddingRight: 77,
    [theme.breakpoints.down('md')]: {
      paddingTop: 0,
      paddingLeft: 21,
      paddingRight: 21,
      textAlign: 'center',
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  padding: {
    padding: spacing.spacing4x,
    [theme.breakpoints.down('md')]: {
      padding: 0
    }
  }
});

interface StateProps {
  errorMessage: string;
}

interface DispatchProps {
  clearErrorMessage: () => void;
  trackPageview: (path: string) => void;
}

interface NeloBrandWrapperProps {
  title?: string;
  subtitle?: string;
  description?: string;
  progress?: number;
  children?: ReactElement | ReactElement[];
  hideBackButton?: boolean;
  hideProgressBar?: boolean;
}

function NeloBrandWrapper(props: NeloBrandWrapperProps & StateProps & DispatchProps): ReactElement {
  const { progress = 1, hideBackButton = false, hideProgressBar = false } = props;
  const classes = useStyles();
  useHandlePageview(props.clearErrorMessage, props.trackPageview);

  return (
    <ThemeProvider theme={theme}>
      <NeloBrandHeader />
      <Grid className={classes.mainContainer} container alignItems="flex-start">
        <Grid item md={3} lg={3} xl={4} />
        <Grid item xs={12} md={6} lg={6} xl={4} container className={classes.contentContainer} direction="column">
          <Grid container justifyContent="space-between" direction="row" className={classes.padding}>
            <NeloProgressBar progress={progress} hideBackButton={hideBackButton} hideProgressBar={hideProgressBar} />
          </Grid>
          <Grid container className={classes.contentText}>
            <Typography variant="h4" className={classes.title}>
              {props.title}
            </Typography>
            <Typography className={classes.subtitle}>{props.subtitle}</Typography>
          </Grid>
          {props.children}
        </Grid>
        <Grid item md={3} lg={3} xl={4} />
      </Grid>
    </ThemeProvider>
  );
}

const mapStateToProps = ({ application }: { application: ApplicationState }): StateProps => ({
  errorMessage: application.errorMessage
});

const mapDispatchToProps = {
  clearErrorMessage: (): EmptyAction<string> => clearErrorMessage(),
  trackPageview: (path: string): EmptyAction<string> => trackPageview(path)
};

export default connect(mapStateToProps, mapDispatchToProps)(NeloBrandWrapper);
