const LogoIcon = ({ lightHeader = false }: { lightHeader?: boolean }): JSX.Element => {
  const className = 'h-10 lg:h-12';

  if (lightHeader) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="114"
        height="50"
        viewBox="0 0 114 50"
        fill="none"
        preserveAspectRatio="xMinYMin"
        className={className}
      >
        <g clipPath="url(#clip0_1862_14)">
          <path
            d="M0 18.3432H5.80046V23.0769H5.89877C6.58696 21.4004 7.865 20.1183 9.73296 19.0335C11.5026 18.0473 13.6655 17.5542 16.025 17.5542C17.4997 17.5542 18.876 17.7514 20.2524 18.2445C21.6288 18.7376 22.8085 19.428 23.7917 20.3155C24.7748 21.3017 25.6596 22.4852 26.2495 23.9645C26.8394 25.4437 27.1343 27.2189 27.1343 29.2899V49.1123H21.3338V30.9664C21.3338 29.4871 21.1372 28.3037 20.744 27.3175C20.3507 26.3313 19.8592 25.4437 19.171 24.852C18.4828 24.2603 17.7946 23.7672 16.9098 23.4714C16.025 23.1755 15.2385 23.0769 14.2553 23.0769C13.0756 23.0769 11.8958 23.2741 10.9127 23.6686C9.92957 24.0631 8.94646 24.6548 8.15996 25.5424C7.37346 26.4299 6.78358 27.4161 6.39031 28.7968C5.99708 30.0789 5.70215 31.6567 5.70215 33.4319V49.3096H0V18.3432Z"
            fill="#067BF2"
          />
          <path
            d="M38.9319 35.8974C38.9319 37.278 39.2269 38.4614 39.8169 39.5461C40.4066 40.6311 41.1931 41.6173 42.0781 42.4061C43.0612 43.1954 44.1427 43.7869 45.4208 44.1815C46.6989 44.5757 47.9769 44.8715 49.3531 44.8715C51.1227 44.8715 52.7942 44.4773 54.0723 43.5896C55.4485 42.7019 56.6281 41.6173 57.8081 40.2365L62.2319 43.5896C58.9877 47.8304 54.4654 49.9011 48.5665 49.9011C46.1089 49.9011 43.9458 49.5069 41.9796 48.6192C40.0135 47.8304 38.3421 46.6469 36.9657 45.1677C35.5894 43.6884 34.5079 42.0119 33.8197 40.0392C33.1315 38.067 32.7383 35.8974 32.7383 33.6291C32.7383 31.3609 33.1315 29.1912 33.918 27.2189C34.7045 25.2465 35.786 23.5699 37.2607 22.0906C38.6369 20.6114 40.3085 19.5265 42.2746 18.639C44.2408 17.85 46.3054 17.3569 48.665 17.3569C51.4177 17.3569 53.6789 17.85 55.5469 18.8362C57.4146 19.8224 58.9877 21.0058 60.1677 22.5837C61.3473 24.1617 62.2319 25.8382 62.8219 27.8105C63.3135 29.7829 63.6085 31.6567 63.6085 33.7277V35.7987C63.6085 35.8974 38.9319 35.8974 38.9319 35.8974ZM57.4146 31.2622C57.4146 29.9802 57.12 28.7967 56.825 27.7119C56.4315 26.6271 55.94 25.6409 55.1535 24.852C54.4654 24.063 53.4823 23.3727 52.4008 22.9782C51.3192 22.5837 50.0412 22.2879 48.5665 22.2879C47.0919 22.2879 45.8139 22.5837 44.6342 23.0768C43.4542 23.5699 42.4712 24.3589 41.5865 25.1479C40.8 26.0354 40.1115 27.0216 39.7185 28.0078C39.2269 29.0926 39.0304 30.0788 39.0304 31.1636C38.9319 31.2622 57.4146 31.2622 57.4146 31.2622Z"
            fill="#067BF2"
          />
          <path d="M69.3104 0H75.1107V49.2112H69.3104V0Z" fill="#067BF2" />
          <path
            d="M91.1358 18.8362C89.1696 19.7238 87.4 20.8086 85.925 22.2879C84.4504 23.7672 83.2708 25.4437 82.4843 27.4161C81.5996 29.3885 81.2062 31.4595 81.2062 33.7278C81.2062 35.996 81.5996 38.067 82.4843 40.0396C83.3693 42.0119 84.4504 43.6884 85.925 45.1677C86.4169 45.6607 87.0066 46.0554 87.4981 46.5484L90.0543 41.42C89.1696 40.5327 88.5796 39.3492 88.0881 38.067C87.5966 36.785 87.4 35.3057 87.4 33.7278C87.4 32.1499 87.5966 30.7692 88.0881 29.3885C88.5796 28.1065 89.2677 26.923 90.0543 26.0355C90.9393 25.1479 92.0204 24.3589 93.2004 23.8658C94.4785 23.3727 95.8546 23.0769 97.4277 23.0769C98.0177 23.0769 98.6077 23.1755 99.0992 23.1755L101.655 18.1459C100.279 17.7514 98.9023 17.5542 97.4277 17.5542C95.265 17.5542 93.1019 17.9487 91.1358 18.8362Z"
            fill="#59C0FE"
          />
          <path
            d="M109.127 22.2879C108.734 21.8935 108.242 21.499 107.849 21.1045L105.195 26.3313C105.883 27.2189 106.473 28.2051 106.964 29.3885C107.456 30.6706 107.652 32.1498 107.652 33.7278C107.652 35.3057 107.456 36.6863 106.964 38.067C106.473 39.3492 105.784 40.5327 104.998 41.42C104.113 42.3077 103.032 43.0965 101.852 43.5896C100.574 44.1815 99.1973 44.3785 97.6246 44.3785C97.1327 44.3785 96.7396 44.3788 96.248 44.28L93.6919 49.4081C94.97 49.7042 96.248 49.9011 97.5261 49.9011C99.7873 49.9011 101.95 49.5069 103.917 48.6192C105.883 47.7315 107.652 46.6469 109.127 45.1677C110.602 43.6885 111.782 42.0119 112.568 40.0392C113.453 38.067 113.846 35.996 113.846 33.7278C113.846 31.4595 113.453 29.3885 112.568 27.4161C111.782 25.5424 110.602 23.7672 109.127 22.2879Z"
            fill="#067BF2"
          />
        </g>
        <defs>
          <clipPath id="clip0_1862_14">
            <rect width="113.846" height="50" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="114"
      height="50"
      viewBox="0 0 114 50"
      fill="none"
      preserveAspectRatio="xMinYMin"
      className={className}
    >
      <g clipPath="url(#clip0_1564_5240)">
        <path
          d="M0 18.3432H5.80046V23.0769H5.89877C6.58696 21.4004 7.865 20.1183 9.73296 19.0335C11.5026 18.0473 13.6655 17.5542 16.025 17.5542C17.4997 17.5542 18.876 17.7514 20.2524 18.2445C21.6288 18.7376 22.8085 19.428 23.7917 20.3155C24.7748 21.3017 25.6596 22.4852 26.2495 23.9645C26.8394 25.4437 27.1343 27.2189 27.1343 29.2899V49.1123H21.3338V30.9664C21.3338 29.4871 21.1372 28.3037 20.744 27.3175C20.3507 26.3313 19.8592 25.4437 19.171 24.852C18.4828 24.2603 17.7946 23.7672 16.9098 23.4714C16.025 23.1755 15.2385 23.0769 14.2553 23.0769C13.0756 23.0769 11.8958 23.2741 10.9127 23.6686C9.92957 24.0631 8.94646 24.6548 8.15996 25.5424C7.37346 26.4299 6.78358 27.4161 6.39031 28.7968C5.99708 30.0789 5.70215 31.6567 5.70215 33.4319V49.3096H0V18.3432Z"
          fill="white"
        />
        <path
          d="M38.9319 35.8974C38.9319 37.278 39.2269 38.4614 39.8169 39.5461C40.4066 40.6311 41.1931 41.6173 42.0781 42.4061C43.0612 43.1954 44.1427 43.7869 45.4208 44.1815C46.6989 44.5757 47.9769 44.8715 49.3531 44.8715C51.1227 44.8715 52.7942 44.4773 54.0723 43.5896C55.4485 42.7019 56.6281 41.6173 57.8081 40.2365L62.2319 43.5896C58.9877 47.8304 54.4654 49.9011 48.5665 49.9011C46.1089 49.9011 43.9458 49.5069 41.9796 48.6192C40.0135 47.8304 38.3421 46.6469 36.9657 45.1677C35.5894 43.6884 34.5079 42.0119 33.8197 40.0392C33.1315 38.067 32.7383 35.8974 32.7383 33.6291C32.7383 31.3609 33.1315 29.1912 33.918 27.2189C34.7045 25.2465 35.786 23.5699 37.2607 22.0906C38.6369 20.6114 40.3085 19.5265 42.2746 18.639C44.2408 17.85 46.3054 17.3569 48.665 17.3569C51.4177 17.3569 53.6789 17.85 55.5469 18.8362C57.4146 19.8224 58.9877 21.0058 60.1677 22.5837C61.3473 24.1617 62.2319 25.8382 62.8219 27.8105C63.3135 29.7829 63.6085 31.6567 63.6085 33.7277V35.7987C63.6085 35.8974 38.9319 35.8974 38.9319 35.8974ZM57.4146 31.2622C57.4146 29.9802 57.12 28.7967 56.825 27.7119C56.4315 26.6271 55.94 25.6409 55.1535 24.852C54.4654 24.063 53.4823 23.3727 52.4008 22.9782C51.3192 22.5837 50.0412 22.2879 48.5665 22.2879C47.0919 22.2879 45.8139 22.5837 44.6342 23.0768C43.4542 23.5699 42.4712 24.3589 41.5865 25.1479C40.8 26.0354 40.1115 27.0216 39.7185 28.0078C39.2269 29.0926 39.0304 30.0788 39.0304 31.1636C38.9319 31.2622 57.4146 31.2622 57.4146 31.2622Z"
          fill="white"
        />
        <path d="M69.3105 0H75.1109V49.2112H69.3105V0Z" fill="white" />
        <path
          d="M91.1366 18.8362C89.1705 19.7238 87.4009 20.8086 85.9259 22.2879C84.4513 23.7672 83.2716 25.4437 82.4851 27.4161C81.6005 29.3885 81.207 31.4595 81.207 33.7278C81.207 35.996 81.6005 38.067 82.4851 40.0396C83.3701 42.0119 84.4513 43.6884 85.9259 45.1677C86.4178 45.6607 87.0074 46.0554 87.499 46.5484L90.0551 41.42C89.1705 40.5327 88.5805 39.3492 88.089 38.067C87.5974 36.785 87.4009 35.3057 87.4009 33.7278C87.4009 32.1499 87.5974 30.7692 88.089 29.3885C88.5805 28.1065 89.2686 26.923 90.0551 26.0355C90.9401 25.1479 92.0213 24.3589 93.2013 23.8658C94.4793 23.3727 95.8555 23.0769 97.4286 23.0769C98.0186 23.0769 98.6086 23.1755 99.1001 23.1755L101.656 18.1459C100.28 17.7514 98.9032 17.5542 97.4286 17.5542C95.2659 17.5542 93.1028 17.9487 91.1366 18.8362Z"
          fill="white"
        />
        <path
          d="M109.126 22.2879C108.733 21.8935 108.242 21.499 107.848 21.1045L105.194 26.3313C105.882 27.2189 106.472 28.2051 106.964 29.3885C107.455 30.6706 107.652 32.1498 107.652 33.7278C107.652 35.3057 107.455 36.6863 106.964 38.067C106.472 39.3492 105.784 40.5327 104.998 41.42C104.113 42.3077 103.031 43.0965 101.851 43.5896C100.573 44.1815 99.1968 44.3785 97.6241 44.3785C97.1322 44.3785 96.7391 44.3788 96.2476 44.28L93.6914 49.4081C94.9695 49.7042 96.2476 49.9011 97.5256 49.9011C99.7868 49.9011 101.949 49.5069 103.916 48.6192C105.882 47.7315 107.652 46.6469 109.126 45.1677C110.601 43.6885 111.781 42.0119 112.568 40.0392C113.452 38.067 113.846 35.996 113.846 33.7278C113.846 31.4595 113.452 29.3885 112.568 27.4161C111.781 25.5424 110.601 23.7672 109.126 22.2879Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1564_5240">
          <rect width="113.846" height="50" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LogoIcon;
