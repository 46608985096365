import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { StrictEffect } from '@redux-saga/types';
import { all, call, debounce, put, SagaGenerator, select, takeEvery } from 'typed-redux-saga';
import { createAction, createReducer, PayloadAction } from 'typesafe-actions';
import { v4 as uuidv4 } from 'uuid';

import NeloApiClient from '../clients/NeloApiClient';
import { isMexicanState, MexicanState } from '../constants/mexicanstates';
import { ApiError } from '../errors/NeloApi';
import { Address } from '../interfaces/nelo-api/Address';
import { AddressAutocompleteResponse, SearchAddress } from '../interfaces/nelo-api/AddressAutocomplete';
import { FingerprintDetails, GeoLocation, ReferralState } from '../interfaces/nelo-api/DeviceState';
import { SignupRequest, SignupResponse } from '../interfaces/nelo-api/Signup';
import { ValidateCurpResponse } from '../interfaces/nelo-api/ValidateCurp';
import i18next from '../localization/i18n';
import { adaptAmountStringToWire } from '../util/Currency';
import { getPosition } from '../util/GeoLocation';
import { doNeloApiRequestWithResponse, safeCall } from '../util/neloApiRequest';
import { updateErrorMessage } from './application';
import { resetAuth, updateAuthState } from './auth';

export const updatePin = createAction('signup/UPDATE_PIN_CODE')<string>();
export const updateEmail = createAction('signup/UPDATE_EMAIL')<string>();
export const updateName = createAction('signup/UPDATE_NAME')<string>();
export const updateCurp = createAction('signup/UPDATE_CURP')<string>();
export const updateReferralData = createAction('signup/UPDATE_REFERRAL_DATA')<ReferralState>();
export const updateCurpPersonalInfo = createAction('signup/UPDATE_CURP_INFO')<ValidateCurpResponse>();
export const submitPersonalInfo = createAction('signup/SUBMIT_PERSONAL_INFO')();
export const submitNameEmail = createAction('signup/SUBMIT_NAME_EMAIL')<NameEmailProps>();
export const submitEmail = createAction('signup/SUBMIT_NAME_EMAIL')<EmailProps>();
export const resetSignup = createAction('signup/RESET_SIGNUP_STATE')();

export const updateOccupation = createAction('signup/UPDATE_OCCUPATION')<string>();
export const updateIncome = createAction('signup/UPDATE_INCOME')<number>();
export const updateCountry = createAction('signup/UPDATE_COUNTRY')<string>();

export const updateLoadingState = createAction('signup/UPDATE_LOADING_STATE')<boolean>();
export const updateUnderwritingStatus = createAction('signup/UPDATE_UNDERWRITING_STATUS')<LoanApplicationState>();
export const updateAutocompleteAddresses = createAction('signup/UPDATE_AUTOCOMPLETE_ADDRESSES')<SearchAddress[]>();
export const updateAddress = createAction('signup/UPDATE_ADDRESS')<Address>();
export const updatePlaceId = createAction('signup/UPDATE_PLACE_ID')<string>();
export const updateStreet = createAction('signup/UPDATE_STREET')<string>();
export const updateBuildingNumber = createAction('signup/UPDATE_BUILDING_NUMBER')<string>();
export const updateInteriorNumber = createAction('signup/UPDATE_INTERIOR_NUMBER')<string>();
export const updateColony = createAction('signup/UPDATE_COLONY')<string>();
export const updateCity = createAction('signup/UPDATE_CITY')<string>();
export const updateState = createAction('signup/UPDATE_STATE')<string>();
export const updatePostalCode = createAction('signup/UPDATE_POSTAL_CODE')<string>();
export const updateLocation = createAction('signup/UPDATE_LOCATION')<GeoLocation | null>();
export const submitSignup = createAction('signup/SUBMIT_SIGNUP')();
export const submitLocation = createAction('signup/SUBMIT_LOCATION')();
export const getUnderwritingStatus = createAction('signup/GET_UNDERWRITING_STATUS')();

// Generate CURP

export interface CurpDetails {
  name?: string;
  paternalLastName?: string;
  maternalLastName?: string;
  gender?: CurpGender;
  dayOfBirth?: number;
  monthOfBirth?: number;
  yearOfBirth?: number;
  stateOfBirth?: MexicanState;
}

export interface GeneratedCurpResponse {
  isCurpValid: boolean;
  generatedCurp: string;
  confirmCurpDescription: string;
}
export type UpdateCurpDetailsPayload = CurpDetails;

export type CurpGender = 'M' | 'F';

export const updateCurpDetailsPayload = createAction('signup/UPDATE_CURP_DETAILS')<UpdateCurpDetailsPayload>();
export const getGenerateCurp = createAction('signup/GENERATE_CURP')<CurpDetails>();
export const updateGeneratedCurpResponse = createAction(
  'signup/UPDATE_GENERATED_CURP_RESPONSE'
)<GeneratedCurpResponse>();

interface AddressState {
  street: string;
  buildingNumber: string;
  interiorNumber: string;
  colony: string;
  city: string;
  state: MexicanState | '';
  postalCode: string;
  placeId: string | null;
}

export interface SignupState {
  name: string;
  pin: string;
  income: number;
  curp: string;
  email: string;
  session: string;
  isLoading: boolean;
  underwritingStatus: LoanApplicationState | null;
  occupation: string;
  birthCountryIso?: string;
  validateCurpResponse: ValidateCurpResponse | null;
  address: AddressState;
  autocompleteAddresses: SearchAddress[];
  location: GeoLocation | null;
  referralData: ReferralState;
  curpDetails: CurpDetails;
  generatedCurpResponse: GeneratedCurpResponse | null;
  temporaryUuid: string;
}

const setLoading = (state: SignupState, action: PayloadAction<string, boolean>): SignupState => ({
  ...state,
  isLoading: action.payload
});

export const initialState: SignupState = {
  name: '',
  pin: '',
  curp: '',
  email: '',
  income: 0,
  occupation: '',
  isLoading: false,
  validateCurpResponse: null,
  session: uuidv4(),
  address: {
    street: '',
    buildingNumber: '',
    interiorNumber: '',
    city: '',
    state: '',
    colony: '',
    postalCode: '',
    placeId: null
  },
  location: null,
  referralData: {
    source: null,
    medium: null,
    campaign: null,
    term: null,
    ad: null,
    url: null,
    isMarketplace: null,
    productId: null,
    variantId: null,
    gclid: null
  },
  autocompleteAddresses: [],
  underwritingStatus: null,
  temporaryUuid: uuidv4(),
  generatedCurpResponse: null,
  curpDetails: {}
};

const getState = ({ signup }: { signup: SignupState }): SignupState => signup;

const resetSignupState = (): SignupState => ({
  ...initialState
});

const setCurpDetails = (state: SignupState, action: PayloadAction<string, UpdateCurpDetailsPayload>): SignupState => {
  const newDetails = action.payload;
  const currentDetails = state.curpDetails;
  return {
    ...state,
    curpDetails: {
      name: newDetails.name ?? currentDetails.name,
      paternalLastName: newDetails.paternalLastName ?? currentDetails.paternalLastName,
      maternalLastName: newDetails.maternalLastName ?? currentDetails.maternalLastName,
      dayOfBirth: newDetails.dayOfBirth ?? currentDetails.dayOfBirth,
      monthOfBirth: newDetails.monthOfBirth ?? currentDetails.monthOfBirth,
      yearOfBirth: newDetails.yearOfBirth ?? currentDetails.yearOfBirth,
      gender: newDetails.gender ?? currentDetails.gender,
      stateOfBirth: newDetails.stateOfBirth ?? currentDetails.stateOfBirth
    }
  };
};

const setGeneratedCurpResponse = (
  state: SignupState,
  action: PayloadAction<string, GeneratedCurpResponse>
): SignupState => ({
  ...state,
  generatedCurpResponse: action.payload
});

const setPin = (state: SignupState, action: PayloadAction<string, string>): SignupState => ({
  ...state,
  pin: action.payload
});

const setCurp = (state: SignupState, action: PayloadAction<string, string>): SignupState => ({
  ...state,
  curp: action.payload
});

const setEmail = (state: SignupState, action: PayloadAction<string, string>): SignupState => ({
  ...state,
  email: action.payload,
  temporaryUuid: uuidv4(),
  session: uuidv4()
});

const setName = (state: SignupState, action: PayloadAction<string, string>): SignupState => ({
  ...state,
  name: action.payload
});

const setOccupation = (state: SignupState, action: PayloadAction<string, string>): SignupState => ({
  ...state,
  occupation: action.payload
});

const setIncome = (state: SignupState, action: PayloadAction<string, number>): SignupState => ({
  ...state,
  income: action.payload
});

const setCountry = (state: SignupState, action: PayloadAction<string, string>): SignupState => ({
  ...state,
  birthCountryIso: action.payload
});

const setUnderwritingStatus = (
  state: SignupState,
  action: PayloadAction<string, LoanApplicationState>
): SignupState => ({
  ...state,
  underwritingStatus: action.payload
});

const setAutocompleteAddresses = (state: SignupState, action: PayloadAction<string, SearchAddress[]>): SignupState => ({
  ...state,
  autocompleteAddresses: action.payload
});

const setAddress = (state: SignupState, action: PayloadAction<string, Address>): SignupState => ({
  ...state,
  address: {
    buildingNumber: action.payload.addressMX?.buildingNumber || '',
    interiorNumber: action.payload.addressMX?.interiorNumber || '',
    street: action.payload.addressMX?.street || '',
    colony: action.payload.addressMX?.colony || '',
    city: action.payload.addressMX?.city || '',
    state: isMexicanState(action.payload.addressMX?.state) ? action.payload.addressMX?.state || '' : '',
    postalCode: action.payload.addressMX?.postalCode || '',
    placeId: action.payload.placeId
  }
});

const setCurpPersonalInfo = (state: SignupState, action: PayloadAction<string, ValidateCurpResponse>): SignupState => ({
  ...state,
  validateCurpResponse: action.payload
});

const setStreet = (state: SignupState, action: PayloadAction<string, string>): SignupState => ({
  ...state,
  address: {
    ...state.address,
    street: action.payload
  }
});

const setInteriorNumber = (state: SignupState, action: PayloadAction<string, string>): SignupState => ({
  ...state,
  address: {
    ...state.address,
    interiorNumber: action.payload
  }
});

const setBuildingNumber = (state: SignupState, action: PayloadAction<string, string>): SignupState => ({
  ...state,
  address: {
    ...state.address,
    buildingNumber: action.payload
  }
});

const setColony = (state: SignupState, action: PayloadAction<string, string>): SignupState => ({
  ...state,
  address: {
    ...state.address,
    colony: action.payload
  }
});

const setCity = (state: SignupState, action: PayloadAction<string, string>): SignupState => ({
  ...state,
  address: {
    ...state.address,
    city: action.payload
  }
});

const setState = (state: SignupState, action: PayloadAction<string, MexicanState>): SignupState => ({
  ...state,
  address: {
    ...state.address,
    state: action.payload
  }
});

const setLocation = (state: SignupState, action: PayloadAction<string, GeoLocation>): SignupState => ({
  ...state,
  location: action.payload
});

const setPostalCode = (state: SignupState, action: PayloadAction<string, string>): SignupState => ({
  ...state,
  address: {
    ...state.address,
    postalCode: action.payload
  }
});

const setReferralData = (state: SignupState, action: PayloadAction<string, ReferralState>): SignupState => ({
  ...state,
  referralData: action.payload
});

export interface EmailProps {
  email: string;
}

export interface NameEmailProps {
  name: string;
  email: string;
}

function* validateEmail(action: PayloadAction<string, EmailProps>): Generator<StrictEffect, void, void> {
  const email = action.payload.email;
  yield* put(updateEmail(email));
  yield* put(resetAuth());
}

function* validateNameEmail(action: PayloadAction<string, NameEmailProps>): Generator<StrictEffect, void, void> {
  const email = action.payload.email;
  const name = action.payload.name;
  yield* put(updateEmail(email));
  yield* put(updateName(name));
}

function* validateCurpSaga(): Generator<StrictEffect, void, void> {
  const state = yield* select(getState);
  const curp = state.curp;
  try {
    const responseBody = yield* call<any, any>(
      doNeloApiRequestWithResponse,
      NeloApiClient.validateCurp.bind(NeloApiClient, curp)
    );
    yield* put(updateCurpPersonalInfo(responseBody as ValidateCurpResponse));
  } catch (error) {
    if (error instanceof ApiError) {
      yield* put(updateErrorMessage({ errorMessage: error.getMessage() }));
    } else {
      yield* put(updateErrorMessage({ errorMessage: i18next.t('errors.unexpected') }));
    }
  }
}

function* fetchGenerateCurp(action: PayloadAction<string, CurpDetails>): SagaGenerator<void> {
  try {
    yield* put(updateLoadingState(true));

    const responseBody = yield* call<any, any>(
      doNeloApiRequestWithResponse,
      NeloApiClient.getGeneratedCurpResponse.bind(NeloApiClient, action.payload)
    );
    const parsedResponse = responseBody as GeneratedCurpResponse;

    yield* put(updateGeneratedCurpResponse(parsedResponse));
    yield* put(updateCurp(parsedResponse.generatedCurp));
  } catch (error) {
    if (error instanceof ApiError) {
      yield* put(updateErrorMessage({ errorMessage: error.getMessage() }));
    } else {
      yield* put(updateErrorMessage({ errorMessage: i18next.t('errors.unexpected') }));
    }
  } finally {
    yield* put(updateLoadingState(false));
  }
}

function* fetchAutocompleteAddresses(action: PayloadAction<string, string>): Generator<StrictEffect, void, void> {
  // Don't try to fetch autocomplete address if user is not logged in
  // This can occur when street is set from url parameters when web checkout first loads
  // const authState = yield* select(getAuthState);
  // if (!authState.accessToken) return;
  const input = action.payload;
  const state = yield* select(getState);
  const session = state.session;
  try {
    const responseBody = yield* call<any, any>(
      doNeloApiRequestWithResponse,
      NeloApiClient.autocompleteAddress.bind(NeloApiClient, input, session)
    );
    yield* put(updateAutocompleteAddresses((responseBody as AddressAutocompleteResponse).addresses));
  } catch (error) {
    if (error instanceof ApiError) {
      yield* put(updateErrorMessage({ errorMessage: error.getMessage() }));
    } else {
      yield* put(updateErrorMessage({ errorMessage: i18next.t('errors.unexpected') }));
    }
  }
}

function* fetchAddress(action: PayloadAction<string, string>): Generator<StrictEffect, void, void> {
  const state = yield* select(getState);
  const placeId = action.payload;
  const session = state.session;
  try {
    const responseBody = yield* call<any, any>(
      doNeloApiRequestWithResponse,
      NeloApiClient.getAddress.bind(NeloApiClient, placeId, session)
    );
    yield* put(updateAddress(responseBody as Address));
  } catch (error) {
    if (error instanceof ApiError) {
      yield* put(updateErrorMessage({ errorMessage: error.getMessage() }));
    } else {
      yield* put(updateErrorMessage({ errorMessage: i18next.t('errors.unexpected') }));
    }
  }
}

async function getGeoLocation(): Promise<GeoLocation | null> {
  try {
    const browserPosition = await getPosition({ timeout: 5000 });
    return {
      latitude: browserPosition.coords.latitude,
      longitude: browserPosition.coords.longitude
    };
  } catch (err) {
    console.error(err); // This error handling was already set up to ignore every error, presumably for a reason. At least now we can see them in the console.
    return null;
  }
}

function* getLocation(): Generator<StrictEffect, void, void> {
  const geoLocation = yield* call(getGeoLocation);
  if (!geoLocation) {
    yield* put(
      updateErrorMessage({
        errorMessage: i18next.t('signup.error.locationPermission')
      })
    );
  }
  yield* put(updateLocation(geoLocation));
}

const getFingerprint = async (): Promise<FingerprintDetails> => {
  const fp = await FingerprintJS.load();
  const result = await fp.get();
  return {
    visitorId: result.visitorId || '',
    confidence: result.confidence || {}
  };
};

export enum LoanApplicationState {
  CREDIT_CHECK_ACCEPTED = 'CREDIT_CHECK_ACCEPTED',
  CREDIT_CHECKED = 'CREDIT_CHECKED',
  PRE_APPROVED = 'PRE_APPROVED',
  RE_UNDERWRITING_ACCEPTED = 'RE_UNDERWRITING_ACCEPTED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  NEEDS_MANUAL_REVIEW = 'NEEDS_MANUAL_REVIEW',
  APPROVED_BY_MODEL = 'APPROVED_BY_MODEL',
  WAITLIST = 'WAITLIST',
  PRE_APPROVED_TREATMENT_B = 'PRE_APPROVED_TREATMENT_B'
}

export function* checkUnderwritingStatus(): Generator<StrictEffect, void, void> {
  try {
    const responseBody: ReturnType<LoanApplicationState | any> = yield* call<any, any>(
      doNeloApiRequestWithResponse,
      NeloApiClient.getUnderwritingState.bind(NeloApiClient)
    );
    yield* put(updateUnderwritingStatus(responseBody.state));
    return responseBody.state;
  } catch (error) {
    console.warn(error);
  }
}

function* doSignup(): Generator<StrictEffect, void, void> {
  const state = yield* select(getState);
  // Remove location requirement from here
  // const geoLocation = yield* call(getGeoLocation);
  const fingerprintDetails = yield* call(getFingerprint);

  const request: SignupRequest = {
    pin: state.pin,
    email: state.email,
    occupation: state.occupation,
    income: state.income === 0 ? null : adaptAmountStringToWire(state.income.toString(), 'MXN'),
    address: {
      addressMX: {
        buildingNumber: state.address.buildingNumber,
        interiorNumber: state.address.interiorNumber,
        street: state.address.street,
        colony: state.address.colony,
        city: state.address.city,
        state: state.address.state || null,
        postalCode: state.address.postalCode,
        delegation: null
      },
      placeId: state.address.placeId,
      countryIso2: 'MX'
    },
    curp: state.curp,
    deviceState: {
      // Skip location data
      location: null,
      fingerprintDetails: {
        visitorId: fingerprintDetails.visitorId
      }
    },
    referralData: state.referralData
  };

  if (state.birthCountryIso) {
    request.birthCountryIso = state.birthCountryIso;
  }

  try {
    yield* put(updateLoadingState(true));
    const responseBody = yield* call<any, any>(
      doNeloApiRequestWithResponse,
      NeloApiClient.signup.bind(NeloApiClient, request)
    );
    yield* put(updateLoadingState(false));
    const { authAction, token, userUuid } = responseBody as SignupResponse;
    yield* put(
      updateAuthState({
        accessToken: token,
        authAction,
        userUuid
      })
    );
  } catch (error) {
    yield* put(updateLoadingState(false));
    if (error instanceof ApiError) {
      yield* put(updateErrorMessage({ errorMessage: error.getMessage() }));
    } else {
      yield* put(updateErrorMessage({ errorMessage: i18next.t('errors.unexpected') }));
    }
  }
}

export function* signupSaga(): SagaGenerator<void> {
  yield* all([
    takeEvery(submitPersonalInfo, safeCall, validateCurpSaga),
    takeEvery(submitNameEmail, safeCall, validateNameEmail),
    takeEvery(submitEmail, safeCall, validateEmail),
    takeEvery(submitLocation, safeCall, getLocation),
    takeEvery(submitSignup, safeCall, doSignup),
    takeEvery(getGenerateCurp, fetchGenerateCurp),
    takeEvery(getUnderwritingStatus, safeCall, checkUnderwritingStatus),
    takeEvery(updatePlaceId, safeCall, fetchAddress),
    debounce(500, updateStreet, safeCall, fetchAutocompleteAddresses)
  ]);
}

export default createReducer(initialState)
  .handleAction(updateStreet, setStreet)
  .handleAction(updateCity, setCity)
  .handleAction(updateColony, setColony)
  .handleAction(updateBuildingNumber, setBuildingNumber)
  .handleAction(updateInteriorNumber, setInteriorNumber)
  .handleAction(updatePostalCode, setPostalCode)
  .handleAction(updateLocation, setLocation)
  .handleAction(updateState, setState)
  .handleAction(updateGeneratedCurpResponse, setGeneratedCurpResponse)
  .handleAction(updateCurpDetailsPayload, setCurpDetails)
  .handleAction(updatePin, setPin)
  .handleAction(updateCurp, setCurp)
  .handleAction(updateCountry, setCountry)
  .handleAction(updateOccupation, setOccupation)
  .handleAction(updateIncome, setIncome)
  .handleAction(updateAutocompleteAddresses, setAutocompleteAddresses)
  .handleAction(updateAddress, setAddress)
  .handleAction(updateCurpPersonalInfo, setCurpPersonalInfo)
  .handleAction(updateUnderwritingStatus, setUnderwritingStatus)
  .handleAction(updateEmail, setEmail)
  .handleAction(updateLoadingState, setLoading)
  .handleAction(updateName, setName)
  .handleAction(resetSignup, resetSignupState)
  .handleAction(updateReferralData, setReferralData);
