function validateEmail(email: string): boolean {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase()) && validateEmailSpelling(email);
}

function validateEmailSpelling(email: string): boolean {
  const domain = email.split('@')[1];
  const domainName = domain.split('.')[0];
  const domainBase = domain.split('.')[1];
  const invalidCombo = ['hotmail', 'yahoo', 'gmail', 'co'];
  const invalidDomainBases = ['cm', 'nt'];
  const invalidDomainNames = [
    'gmial',
    'gnail',
    'gmaill',
    'yaho',
    'yaoo',
    'yahooo',
    'yhaoo',
    'gnial',
    'hotmai',
    'hotmiall',
    'hotmaill',
    'hotmall'
  ];
  if (invalidDomainNames.includes(domainName)) {
    return false;
  }
  if (invalidDomainBases.includes(domainBase)) {
    return false;
  }
  if (domainName === 'nelo' && domainBase === 'mx') {
    return false;
  }
  return !(invalidCombo.includes(domainName) && invalidCombo.includes(domainBase));
}

export default validateEmail;
