import '../localization/i18n';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { spacing } from '../styling';
import Nelo from './Nelo';

export const useStyles = makeStyles({
  questionText: {
    fontWeight: 'bold'
  },
  button: {
    marginTop: spacing.spacing3x,
    marginBottom: spacing.spacing3x,
    paddingLeft: spacing.spacing4x,
    paddingRight: spacing.spacing4x,
    paddingTop: spacing.spacing2x,
    paddingBottom: spacing.spacing2x
  }
});

function ErrorPage(): ReactElement {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Nelo.BrandWrapper title={t('errorPage.title')}>
      <Typography variant="body1" className={classes.questionText}>
        {t('errorPage.body')}
      </Typography>
    </Nelo.BrandWrapper>
  );
}

export default ErrorPage;
