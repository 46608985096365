import React, { ReactElement } from 'react';

const Arrow = ({ className }: { className?: string }): ReactElement => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path d="M13.4216 2.11625L5.39145 10.1464L4.53789 11H5.745H23.5V13H5.745H4.53942L5.39113 13.8532L13.4082 21.8847L12 23.2929L0.707107 12L12.0012 0.70586L13.4216 2.11625Z" />
    </svg>
  );
};

export default Arrow;
