import '../localization/i18n';

import Autocomplete from '@material-ui/core/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PayloadAction } from 'typesafe-actions';

import { AnalyticEvent } from '../analytics/AnalyticEvent';
import { MexicanState, MexicanStates } from '../constants/mexicanstates';
import { emitAnalyticEventAction } from '../ducks/analytics';
import { ApplicationProps, ApplicationState, clearErrorMessage } from '../ducks/application';
import {
  SignupState,
  updateBuildingNumber,
  updateCity,
  updateColony,
  updatePlaceId,
  updatePostalCode,
  updateState,
  updateStreet
} from '../ducks/signup';
import { SearchAddress } from '../interfaces/nelo-api/AddressAutocomplete';
import { isEmpty } from '../util/isEmpty';
import Nelo from './Nelo';
import { TextInputAdornment } from './Nelo/NeloTextField';

interface SelectElement {
  value: unknown;
}

interface DispatchProps {
  updatePlaceId: (placeId: string) => void;
  updateStreet: (street: string) => void;
  updateBuildingNumber: (buildingNumber: string) => void;
  updateColony: (colony: string) => void;
  updateCity: (city: string) => void;
  updateState: (state: string) => void;
  updatePostalCode: (postalCode: string) => void;
  emitEvent: (props: AnalyticEvent) => void;
}

interface StateProps extends ApplicationProps {
  street: string;
  buildingNumber: string;
  colony: string;
  city: string;
  state: MexicanState | '';
  postalCode: string;
  autocompleteAddresses: SearchAddress[];
}

function AddressEntry(props: DispatchProps & StateProps): ReactElement {
  const classes = Nelo.useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const handleVerify = (): void => {
    clearErrorMessage();
    setHasSubmitted(true);
    if (
      !isEmpty(props.street) &&
      !isEmpty(props.buildingNumber) &&
      !isEmpty(props.city) &&
      !isEmpty(props.state) &&
      !isEmpty(props.postalCode)
    ) {
      globalThis.userStreet = props.street;
      globalThis.userRegion = props.state;
      globalThis.userPostalCode = props.postalCode;
      globalThis.userCountry = 'MX';
      props.emitEvent({ name: 'WEB_SIGNUP_SUBMIT_ADDRESS', params: { verify: 'true' } });
      navigate('/signup/pin');
    }
  };
  return (
    <Nelo.BrandWrapper title={t('signup.address.title')} subtitle={t('signup.address.subtitle')} progress={5}>
      <Grid container justifyContent="space-between" direction="row">
        <Autocomplete
          id="combo-box-demo"
          freeSolo={true}
          inputValue={props.street}
          options={props.autocompleteAddresses}
          getOptionLabel={(option): string => option.description}
          onChange={(e, newValue): void | null | '' => {
            if (!newValue || typeof newValue === 'string') {
              return;
            }
            props.updatePlaceId(newValue.placeId);
          }}
          className={`${classes.textEntry} ${classes.smallTextEntry}`}
          renderInput={(params): ReactElement => (
            <TextField
              {...params}
              required
              autoFocus
              error={hasSubmitted && isEmpty(props.street)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void => props.updateStreet(e.target.value)}
              label={t('signup.address.street.label')}
            />
          )}
        />
        <Nelo.TextField
          required
          name="Address Number"
          error={hasSubmitted && isEmpty(props.buildingNumber)}
          valid={!isEmpty(props.buildingNumber)}
          className={`${classes.textEntry} ${classes.smallTextEntry}`}
          label={t('signup.address.number.label')}
          setValue={props.updateBuildingNumber}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => props.updateBuildingNumber(e.target.value)}
          value={props.buildingNumber}
        />
      </Grid>
      <Nelo.TextField
        name="Address Colony"
        required
        error={hasSubmitted && isEmpty(props.colony)}
        valid={!isEmpty(props.colony)}
        className={classes.textEntry}
        label={t('signup.address.colony.label')}
        setValue={props.updateColony}
        onChange={(e: React.ChangeEvent<HTMLInputElement>): void => props.updateColony(e.target.value)}
        value={props.colony}
      />
      <Nelo.TextField
        required
        name="Address City"
        error={hasSubmitted && isEmpty(props.city)}
        valid={!isEmpty(props.city)}
        className={classes.textEntry}
        label={t('signup.address.city.label')}
        setValue={props.updateCity}
        onChange={(e: React.ChangeEvent<HTMLInputElement>): void => props.updateCity(e.target.value)}
        value={props.city}
      />
      <Grid container justifyContent="space-between" direction="row">
        <Nelo.TextField
          name="Address Postal Code"
          required
          valid={parseInt(props.postalCode) > 0 && props.postalCode.length > 4}
          error={
            (hasSubmitted && isEmpty(props.postalCode)) || (!isEmpty(props.postalCode) && !parseInt(props.postalCode))
          }
          className={`${classes.textEntry} ${classes.smallTextEntry}`}
          label={t('signup.address.postal.label')}
          setValue={updatePostalCode}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => props.updatePostalCode(e.target.value)}
          value={props.postalCode}
        />
        <FormControl required variant="outlined" className={`${classes.textEntry} ${classes.smallTextEntry}`}>
          <InputLabel htmlFor="address-state-entry">{t('signup.address.state.label')}</InputLabel>
          <Select
            name="Address State"
            id="standard-basic"
            native
            error={hasSubmitted && isEmpty(props.state)}
            value={props.state}
            onChange={(e: React.ChangeEvent<SelectElement>): void => props.updateState(e.target.value as string)}
            label={t('signup.address.state.label')}
            inputProps={{
              name: 'address-state',
              id: 'address-state-entry',
              endAdornment: <TextInputAdornment value={props.state} valid={!isEmpty(props.state)} />
            }}
          >
            <option aria-label="None" value="" />
            {MexicanStates.map((state: MexicanState) => (
              <option key={state} value={state}>
                {state}
              </option>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Nelo.Button
        disabled={
          isEmpty(props.street) ||
          isEmpty(props.buildingNumber) ||
          isEmpty(props.city) ||
          isEmpty(props.state) ||
          isEmpty(props.postalCode)
        }
        isLoading={props.isLoading}
        text={t('signup.address.buttonText')}
        onClick={handleVerify}
      />
    </Nelo.BrandWrapper>
  );
}

const mapDispatchToProps = {
  updatePlaceId: (placeId: string): PayloadAction<string, string> => updatePlaceId(placeId),
  updateStreet: (street: string): PayloadAction<string, string> => updateStreet(street),
  updateBuildingNumber: (buildingNumber: string): PayloadAction<string, string> => updateBuildingNumber(buildingNumber),
  updateColony: (colony: string): PayloadAction<string, string> => updateColony(colony),
  updateCity: (city: string): PayloadAction<string, string> => updateCity(city),
  updateState: (state: string): PayloadAction<string, string> => updateState(state),
  updatePostalCode: (postalCode: string): PayloadAction<string, string> => updatePostalCode(postalCode),
  emitEvent: (props: AnalyticEvent): PayloadAction<string, AnalyticEvent> => emitAnalyticEventAction(props)
};

const mapStateToProps = ({
  signup,
  application
}: {
  signup: SignupState;
  application: ApplicationState;
}): StateProps => ({
  street: signup.address.street,
  buildingNumber: signup.address.buildingNumber,
  colony: signup.address.colony,
  city: signup.address.city,
  state: signup.address.state,
  postalCode: signup.address.postalCode,
  autocompleteAddresses: signup.autocompleteAddresses,
  isLoading: application.isLoading && !application.errorMessage
});

export default connect(mapStateToProps, mapDispatchToProps)(AddressEntry);
