import styled from 'styled-components';

import ImageBoxThree from '../../Nelo/img/cardCard.png';
import ImageBoxOne from '../../Nelo/img/Product-Servicios-Recargas.png';
import ImageBoxFour from '../../Nelo/img/Products-Compras-quincenas.png';
import ImageBoxTwo from '../../Nelo/img/Products-Prestamos-Efectivo.png';
import Card from './Card';

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 32px;

  @media screen and (min-width: 1024px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(19, 56px);
    column-gap: 56px;
    row-gap: 0;
  }

  .one {
    @media screen and (min-width: 1024px) {
      grid-column-start: 1;
      grid-row: 1 / span 10;
    }
  }

  .two {
    @media screen and (min-width: 1024px) {
      grid-column-start: 1;
      grid-row: 12 / span 8;
    }
  }

  .three {
    @media screen and (min-width: 1024px) {
      grid-column-start: 2;
      grid-row: 1 / span 8;
    }
  }

  .four {
    @media screen and (min-width: 1024px) {
      grid-column-start: 2;
      grid-row: 10 / span 10;
    }
  }

  .green_background {
    background: linear-gradient(169deg, #eeffa0 1.86%, #78d8aa 90.86%);
    padding: 2.5rem;
  }

  .rose_background {
    background: linear-gradient(169deg, #ffe4f7 1.86%, #ff97df 90.86%);
    padding: 2.5rem;
  }

  .blue_background {
    background: linear-gradient(169deg, #aed9ff 1.86%, #58a9f9 90.86%);
    padding: 2.5rem;
  }

  .purple_background {
    background: linear-gradient(169deg, #e6e2ff 1.86%, #b4c8ff 90.86%);
    padding: 2.5rem;
  }
`;

const Cards = (): JSX.Element => {
  return (
    <div className="ctn mt-20 md:mt-40">
      <h2 className="text-center text-darkGrey tracking-[3px] uppecase mb-14 sm:mb-24">NELO ES CRÉDITO A TU MEDIDA</h2>
      <Container>
        <Card
          componentClass="one"
          title="Pagos de servicios y recargas"
          description="Paga tus recibos de CFE, agua, gas y recarga tu celular. Tú eliges en cuantas quincenas quieres pagar."
          source={ImageBoxOne}
          color={'green'}
          imgHeight={299}
          imgWidth={320}
        />
        <Card
          componentClass="three"
          title="Compras en línea y en apps"
          description="Compra hoy y paga después en Nike, Walmart, Amazon, Shein, Cinépolis y muchas tiendas más, todo desde tu app."
          source={ImageBoxFour}
          color={'rose'}
          imgHeight={267}
          imgWidth={305}
        />
        <Card
          componentClass="two"
          title="Compras en tiendas físicas"
          description="La Tarjeta Física VIP (disponible para usuarios VIP) y virtual, te permiten pagar en tus comercios favoritos."
          source={ImageBoxThree}
          color={'purple'}
          imgHeight={459}
          imgWidth={327}
        />
        <Card
          componentClass="four"
          title="Préstamos en efectivo"
          description="Elige el monto que deseas y recibe tu transferencia de inmediato, pagando en los plazos que mejor se te acomoden."
          source={ImageBoxTwo}
          color={'blue'}
          imgHeight={320}
          imgWidth={355}
        />
      </Container>
    </div>
  );
};

export default Cards;
