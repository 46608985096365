import '../localization/i18n';

import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { AnalyticEvent } from '../analytics/AnalyticEvent';
import { emitAnalyticEventAction } from '../ducks/analytics';
import { EmailProps, SignupState, submitEmail, updateReferralData } from '../ducks/signup';
import useMobileDetect from '../hooks/useMobileDetect';
import useTrackReferrer from '../hooks/useTrackReferrer';
import { ReferralState } from '../interfaces/nelo-api/DeviceState';
import { colors, spacing } from '../styling';
import validate from '../util/inputs';
import Nelo from './Nelo';
import { theme } from './Nelo/NeloBrandWrapper';

export const useStyles = makeStyles({
  inputContainer: {
    [Nelo.theme.breakpoints.down('md')]: {
      textAlign: 'center',
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '95%'
    }
  },
  input: {
    borderRadius: 100,
    border: '2px solid #067BF2',
    width: 500,
    height: 60,
    color: colors.neloBlue,
    fontWeight: 600,
    '& .MuiOutlinedInput-input': {
      padding: '18.5px 24px'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none !important'
    },
    marginBottom: spacing.spacing2x,
    background: colors.white,
    borderShadow: 'none',
    '& .MuiInput-underline:after': {
      border: `3px solid ${colors.neloBlue}`
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'none'
      },
      '&:hover fieldset': {
        border: `2.5px solid ${colors.neloBlue}`
      },
      '&.Mui-focused fieldset': {
        border: `2.5px solid ${colors.neloBlue}`
      }
    },
    [Nelo.theme.breakpoints.down('md')]: {
      width: '90%',
      fontSize: spacing.spacing2x,
      maxWidth: 500,
      height: 50,
      borderRadius: 100,
      '& .MuiOutlinedInput-input': {
        height: 40,
        padding: '5.5px 14px 5.5px 14px'
      }
    },
    [Nelo.theme.breakpoints.down('sm')]: {
      marginBottom: spacing.spacing1x,
      fontSize: spacing.spacing1x,
      borderRadius: 100,
      width: '90%',
      height: 40,
      '& .MuiOutlinedInput-input': {
        height: 30,
        padding: '5.5px 14px 5.5px 14px'
      }
    }
  },
  link: {
    color: colors.white,
    [Nelo.theme.breakpoints.down('sm')]: {
      color: colors.white
    }
  },
  button: {
    height: 64,
    width: 240,
    marginTop: 0,
    marginLeft: -200,
    borderRadius: `0px 100px 100px 0px`,
    fontSize: spacing.spacing3x,
    fontWeight: 600,
    color: colors.white,
    '& .css-d2rydc-MuiInputBase-input-MuiOutlinedInput-input:-webkit-autofill': {
      borderRadius: `0px 100px 100px 0px !important`
    },
    [Nelo.theme.breakpoints.down('md')]: {
      fontSize: spacing.spacing2x,
      marginLeft: -160,
      width: 160,
      height: 54
    },
    [Nelo.theme.breakpoints.down('sm')]: {
      fontSize: spacing.spacing2x,
      marginLeft: -120,
      width: 140,
      height: 44
    }
  },
  description: {
    marginTop: 20,
    fontFamily: 'Nunito Sans',
    fontSize: 20,
    color: colors.white,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      fontSize: 14,
      marginTop: 0
    }
  },
  container: {
    marginTop: spacing.spacing3x,
    [Nelo.theme.breakpoints.down('md')]: {
      overflow: 'hidden' /* Hide scrollbars */,
      marginTop: spacing.spacing2x
    },
    [Nelo.theme.breakpoints.down('sm')]: {
      position: 'absolute',
      bottom: spacing.spacing1x,
      marginTop: spacing.spacing1x
    }
  },
  green: {
    color: colors.neloGreen
  }
});

interface DispatchProps {
  submitEmail: (props: EmailProps) => void;
  submitAnalyticEvent: (props: AnalyticEvent) => void;
  updateReferralData: (data: ReferralState) => void;
}

interface StateProps {
  referralData: ReferralState;
  temporaryUuid: string;
}

function LandingPage(props: DispatchProps & StateProps): ReactElement {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const { isMobile } = useMobileDetect();
  const isSmallScreen = useMediaQuery(Nelo.theme.breakpoints.down('sm'));
  const showMobileDesign = isMobile || isSmallScreen;
  const [value, setValue] = useState<string>('');
  const [valid, setValid] = useState<boolean | null>(null);
  const [submittable, setSubmittable] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [searchParams] = useSearchParams();

  useTrackReferrer(props.updateReferralData, props.referralData);

  let coloredTitle = t('signup.landing.title').replace(/quincenas/g, '<span style="color:#E1FF58">quincenas</span>');
  coloredTitle = coloredTitle.replace(/todas/g, '<span style="color:#E1FF58">todas</span>');
  coloredTitle = coloredTitle.replace(/later/g, '<span style="color:#E1FF58">later</span>');
  coloredTitle = coloredTitle.replace(/everywhere/g, '<span style="color:#E1FF58">everywhere</span>');

  const ad = searchParams.get('utm_ad') || null;
  const email = searchParams.get('utm_email') || null;

  useEffect(() => {
    // redirect to the referral landing page
    if (email && ad === 'referral_websu_test') {
      props.submitAnalyticEvent({ name: 'WEB_SIGNUP_PERSONALIZED_LANDING_PAGE' });
      props.submitAnalyticEvent({ name: 'WEB_SIGNUP_SUBMIT_EMAIL', params: { email: value } });
      props.submitEmail({ email: value });
      goNext();
    }
    if (submitted && valid) {
      props.submitAnalyticEvent({ name: 'WEB_SIGNUP_SUBMIT_EMAIL', params: { email: value } });
      props.submitEmail({ email: value });
      globalThis.userEmail = value;
      goNext();
    }
  }, [submitted, valid, value, email, ad]);

  const goNext = (): void => {
    navigate('/signup/phone-email');
  };

  useEffect(() => {
    if (validate.email(value)) {
      setSubmittable(true);
      setValid(true);
    } else {
      if (value.length > 8) {
        setSubmittable(true);
      }
    }
  }, [value, valid]);

  return (
    <Nelo.BrandLandingPage background="landing" title={coloredTitle} subtitle={t('signup.landing.subtitle')}>
      <Grid container className={classes.container}>
        <Grid item md={12} lg={8} className={classes.inputContainer}>
          <Nelo.TextField
            name="Email"
            className={classes.input}
            placeholder={t('signup.landing.label')}
            autocomplete="off"
            type="text"
            error={!valid && submittable}
            required
            autoFocus
            value={value}
            onChange={(e): void => setValue(e.target.value)}
            setValue={setValue}
            valid={validate.email(value)}
          />
          <Nelo.Button
            className={classes.button}
            text={showMobileDesign ? t('signup.landing.buttonMobile') : t('signup.landing.button')}
            onClick={(): void => {
              setSubmitted(true);
            }}
          />
          <Typography className={classes.description}>
            {t('signup.landing.description')}
            <Link
              className={classes.link}
              underline="always"
              href="https://nelo.mx/privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('signup.landing.description.link')}
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Nelo.BrandLandingPage>
  );
}

const mapStateToProps = ({ signup }: { signup: SignupState }): StateProps => ({
  referralData: signup.referralData,
  temporaryUuid: signup.temporaryUuid
});

const mapDispatchToProps = {
  submitEmail: (props: EmailProps): any => submitEmail(props),
  updateReferralData: (data: ReferralState): any => updateReferralData(data),
  submitAnalyticEvent: (props: AnalyticEvent): any => emitAnalyticEventAction(props)
};

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);
