import TestimonySlider from '../TestimonySlider';

const HomeCarousel = (): JSX.Element => {
  return (
    <div className="mt-20 sm:mt-32">
      <h2 className="text-center text-darkGrey tracking-[3px] uppecase mb-16 sm:mb-24">CRÉDITO A TU MEDIDA</h2>
      <p className="text-center font-light tracking-[-0.44px] text-[42px] leading-[50px] md:text-[60px] md:leading-[72px] max-w-2xl m-auto">
        <span className="text-primary font-normal">+1 millón</span> de descargas nos respaldan
      </p>
      <TestimonySlider />
    </div>
  );
};

export default HomeCarousel;
