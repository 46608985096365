import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { colors, spacing } from '../../styling';
import AppDownload from '../../svg/appDownload.svg';
import AppStore from '../../svg/appStore.svg';
import GooglePlay from '../../svg/googlePlay.svg';
import Nelo from './';

export const useStyles = makeStyles({
  qrTitle: {
    color: '#FFFFFF',
    fontSize: 24,
    fontWeight: 600,
    marginTop: 30,
    [Nelo.theme.breakpoints.down('md')]: {
      color: colors.white,
      fontSize: 20,
      textAlign: 'center'
    }
  },
  qr: {
    marginTop: spacing.spacing3x,
    borderRadius: 10,
    [Nelo.theme.breakpoints.down('md')]: {
      textAlign: 'center'
    }
  },
  image: {
    width: 180,
    marginTop: spacing.spacing4x,
    marginBottom: spacing.spacing4x
  },
  qrContainer: {
    [Nelo.theme.breakpoints.down('md')]: {
      textAlign: 'center',
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '95%'
    }
  }
});

const DownloadLink = (): ReactElement => {
  const classes = useStyles();
  const { t } = useTranslation();
  const userAgent = navigator.userAgent;

  const isIOS = /iPad|iPhone|iPod/.test(userAgent);
  const isAndroid = /android/i.test(userAgent);

  if (isIOS) {
    return (
      <div>
        <a href="https://apps.apple.com/mx/app/nelo/id1477987798">
          <img className={classes.image} src={AppStore} loading="lazy" alt="Download on the App Store" />
        </a>
      </div>
    );
  } else if (isAndroid) {
    return (
      <div>
        <a href="https://play.google.com/store/apps/details?id=co.nelo.nelo&amp;gl=MX">
          <img className={classes.image} src={GooglePlay} loading="lazy" alt="Get it on Google Play" />
        </a>
      </div>
    );
  } else {
    return (
      <div>
        <Typography variant="body1" className={classes.qrTitle}>
          {t('signup.completed.download.title')}
        </Typography>
        <img src={AppDownload} className={classes.qr} alt="QR Code" />
      </div>
    );
  }
};

export default DownloadLink;
