import Dinero, { Dinero as Money } from 'dinero.js';

import { CurrencyCode, WireMoney } from './Money';

const AMOUNT_E5 = 5;

function getAmountE5(money: Money): number {
  return money.getAmount() * Math.pow(10, AMOUNT_E5 - money.getPrecision());
}

export function getValueFromAmount(amount: WireMoney): number {
  return Math.ceil(amount.amountE5 * Math.pow(10, 0 - AMOUNT_E5));
}

export function getDineroFromString(decimalDollarAmount: string, currencyCode: CurrencyCode): Money {
  const amountWithPrecision = Number(decimalDollarAmount).toFixed(2);
  const strippedAmount = amountWithPrecision.replace(/\./g, '');
  const amountInCents = parseInt(strippedAmount, 10);
  return Dinero({ amount: amountInCents, currency: currencyCode });
}

export function adaptAmountStringToWire(amountString: string, currencyCode: CurrencyCode): WireMoney {
  const amount = getDineroFromString(amountString, currencyCode);
  return {
    amountE5: getAmountE5(amount),
    currencyCode: amount.getCurrency()
  };
}

const currencyValueRegex = /(\d*)(\.\d*)?/;

export const toFixed = (rawText: string): string => {
  if (!rawText || !Number.parseFloat(rawText)) {
    return '';
  }
  const currencyValue = currencyValueRegex.exec(rawText);
  if (!currencyValue || !currencyValue[0]) {
    return '';
  }
  return Number.parseFloat(currencyValue[0]).toFixed(2);
};

export const amountE5ToDisplayValue = (amountE5: number, currencyCode: CurrencyCode): string => {
  const amountInCents = amountE5 * Math.pow(10, 2 - AMOUNT_E5);
  const dinero = Dinero({ amount: amountInCents, currency: currencyCode });
  return dinero.toFormat('0.00');
};
