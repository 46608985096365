import ErrorDetails from '../interfaces/nelo-api/ErrorDetails';
import I18n from '../localization/i18n';

export class ApiError extends Error {
  public constructor(message: string, canRetry: boolean, response?: Response, payload?: ErrorDetails) {
    super(message);
    this.response = response;
    this.details = payload;
    this.canRetry = canRetry;
    this.getServerMessage = this.getServerMessage.bind(this);
    this.getMessage = this.getMessage.bind(this);
    Object.setPrototypeOf(this, ApiError.prototype);
  }

  public canRetry: boolean;
  public response?: Response;
  private details?: ErrorDetails;

  public getServerMessage(): string | null {
    return this.details && this.details.message ? this.details.message : null;
  }

  public getMessage(): string {
    return this.getServerMessage() || I18n.t('errors.genericError');
  }
}

export class BadInputError extends ApiError {
  public constructor(response: Response, payload?: ErrorDetails, message = 'Bad Input error') {
    super(message, false, response, payload);
    Object.setPrototypeOf(this, BadInputError.prototype);
  }

  public getMessage(): string {
    return this.getServerMessage() || I18n.t('errors.internalServerError');
  }
}

export class ForbiddenError extends ApiError {
  public constructor(response: Response, payload?: ErrorDetails, message = 'Forbidden error') {
    super(message, false, response, payload);
    Object.setPrototypeOf(this, ForbiddenError.prototype);
  }

  public getMessage(): string {
    return this.getServerMessage() || I18n.t('errors.forbiddenError');
  }
}

export class UnauthenticatedError extends ApiError {
  public constructor(response: Response, payload?: ErrorDetails) {
    super('Authentication required', false, response, payload);
    Object.setPrototypeOf(this, UnauthenticatedError.prototype);
  }

  public getMessage(): string {
    return this.getServerMessage() || I18n.t('errors.unauthenticatedError');
  }
}

export class InternalServerError extends ApiError {
  public constructor(response: Response) {
    super('Internal server error', true, response);
    Object.setPrototypeOf(this, InternalServerError.prototype);
  }

  public getMessage(): string {
    return this.getServerMessage() || I18n.t('errors.internalServerError');
  }
}

export class NoResponseError extends ApiError {
  public constructor(response?: Response) {
    super('No response received from server', true, response);
    Object.setPrototypeOf(this, NoResponseError.prototype);
  }

  public getMessage(): string {
    return I18n.t('errors.networkError');
  }
}

export class GenericError extends ApiError {
  public constructor(response: Response, payload?: ErrorDetails) {
    super('Unknown API error', false, response, payload);
    Object.setPrototypeOf(this, GenericError.prototype);
  }
}

export class FraudError extends ForbiddenError {
  public constructor(response: Response, payload: ErrorDetails) {
    super(response, payload, 'Fraud error');
    Object.setPrototypeOf(this, FraudError.prototype);
  }
}

export class SignupBlockedError extends ForbiddenError {
  public constructor(response: Response, payload: ErrorDetails) {
    super(response, payload, 'Signup blocked error');
    this.signupBlocked = payload.signupBlocked || false;
    this.message = payload.message || '';
    Object.setPrototypeOf(this, SignupBlockedError.prototype);
  }

  public signupBlocked: boolean;
  public message: string;
}

export class UnprocessableEntityError extends ApiError {
  public constructor(response: Response, payload: ErrorDetails) {
    super('Unprocessable Entity Error', false, response, payload);
    Object.setPrototypeOf(this, UnprocessableEntityError.prototype);
  }
}

export class TooManyRequestsError extends ApiError {
  public constructor(response: Response, payload: ErrorDetails) {
    super('Too Many Requests', false, response, payload);
    Object.setPrototypeOf(this, UnprocessableEntityError.prototype);
  }
}
