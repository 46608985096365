import Box from '@material-ui/core/Grid';
import React, { ReactElement } from 'react';

import NeloLogo from '../../nelo_logo.svg';
import { useStyles } from './NeloBrandWrapper';

const NeloBrandHeader = (): ReactElement => {
  const classes = useStyles();
  return (
    <Box className={classes.bannerContainer}>
      <img src={NeloLogo} className={classes.icon} />
    </Box>
  );
};

export default NeloBrandHeader;
