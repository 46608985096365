import { ReactElement, ReactNode, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { ApplicationState } from '../ducks/application';
import { AuthState } from '../ducks/auth';
import { generateSiftJS } from './sift/generateSiftJS';

interface StateProps {
  userUuid: string;
  sessionId: string;
}

interface UseSiftProps extends StateProps {
  children: ReactNode;
}

const UseSift = (props: UseSiftProps): ReactElement => {
  const { sessionId = '', userUuid = '' } = props;
  const [siftJS, setSiftJS] = useState<HTMLScriptElement | null>(null);

  useEffect((): void => {
    if (sessionId) {
      updateSiftJS(sessionId, userUuid);
    }
  }, [sessionId]);

  useEffect((): void => {
    if (siftJS && userUuid) {
      siftJS.remove();
      updateSiftJS(sessionId, userUuid);
    }
  }, [userUuid]);

  const updateSiftJS = (newSessionId: string, newUserUuid: string): void => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = generateSiftJS(newSessionId, newUserUuid);
    document.body.prepend(script);
    setSiftJS(script);
  };

  return <>{props.children}</>;
};

const mapStateToProps = ({ auth, application }: { auth: AuthState; application: ApplicationState }): StateProps => ({
  userUuid: auth.userUuid,
  sessionId: application.sessionId
});

export default connect(mapStateToProps)(UseSift);
