import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
declare global {
  interface Window {
    _sift?: any;
    dataLayer?: any[];
  }
}

const useHandlePageview = (clearErrorMessage: () => void, trackPageView: (pathname: string) => void): void => {
  const location = useLocation();
  useEffect(() => {
    clearErrorMessage();
    const sift = window._sift;
    if (sift) {
      sift?.push(['_trackPageview']);
    }
    const gtm = window.dataLayer;
    if (gtm) {
      gtm.push({
        event: 'pageview',
        page: {
          url: location.pathname
        }
      });
    }
    trackPageView(location.pathname);
  }, [location]);
};

export default useHandlePageview;
