const API_HOST = 'api-v2';

const BASE_URL = `https://${API_HOST}.nelo.co/`;

export function makeUrl(path: string): string {
  return `${BASE_URL}${path}`;
}

export const SEND_MOBILE_VERIFICATION = makeUrl('code/send');
export const CONFIRM_MOBILE_VERIFICATION = makeUrl('code/confirm');
export const VALIDATE_CURP = makeUrl('v3/curp/validate');
export const AUTOCOMPLETE_ADDRESS = makeUrl('address/autocomplete');
export const WEB_UNDERWRITING = makeUrl('signup/web-underwriting');
export const SIGNUP = makeUrl('v4/signup');
export const UNDERWRITING_APPLICATION_STATE = makeUrl('profile/loan-application/state');
export const ADDRESS = makeUrl('address');
export const GENERATE_CURP = makeUrl('curp/generate');
