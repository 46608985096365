import '../localization/i18n';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import useTrackData from '../hooks/useTrackData';
import Nelo from './Nelo';
import DownloadLink from './Nelo/DownloadLink';

export const useStyles = makeStyles({
  qrContainer: {
    [Nelo.theme.breakpoints.down('md')]: {
      textAlign: 'center',
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '95%'
    }
  }
});

function ApplicationCompletedExistingUser(): ReactElement {
  const { t } = useTranslation();
  const classes = useStyles();
  useTrackData('existing_user');

  return (
    <Nelo.BrandCompletionPage
      title={t('signup.completed.loggedin.title')}
      subtitle={t('signup.completed.loggedin.subtitle')}
    >
      <Grid className={classes.qrContainer}>
        <DownloadLink />
      </Grid>
    </Nelo.BrandCompletionPage>
  );
}

export default ApplicationCompletedExistingUser;
