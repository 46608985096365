import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useTrackData = (event: any): void => {
  const location = useLocation();
  useEffect(() => {
    const gtm = window.dataLayer;
    if (gtm) {
      gtm.push({
        event: event
      });
    }
  }, [location, event]);
};

export default useTrackData;
