import styled from '@emotion/styled';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { EmailProps, submitEmail } from '../../../ducks/signup';

const StyledForm = styled.form`
  font-size: 18px;
  margin-top: 53px;
  margin-left: 3px;
  display: flex;
  flex-direction: row;
  justify-content: left;

  @media screen and (min-width: 1024px) {
    font-size: 22px;
  }
`;

const StyledInput = styled.input`
  padding: 8px 16px;
  box-sizing: border-box;
  border-radius: 95px;
  display: block;
  color: var(--darkGrey);
  display: inline-block;
  border-radius: 95px 0 0 95px;

  &:hover {
    color: var(--primary);
  }

  &:focus {
    outline: none;
    border: none;
  }

  @media screen and (min-width: 1024px) {
    padding: 18px 32px;
  }
`;

const StyledButton = styled.button`
  color: var(--white);
  box-sizing: border-box;
  background-color: var(--primary);
  padding: 10px 16px;
  border-radius: 95px;
  text-transform: uppercase;
  margin-top: 16px;
  transition: 0.2s;
  border-radius: 0px 95px 95px 0px;
  margin-top: 0;

  &:hover {
    cursor: pointer;
    background: var(--citron);
    color: var(--primary);
  }
`;

interface Props {
  submitEmail: (email: EmailProps) => void;
}

const InputBanner = (props: Props): JSX.Element => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSubmit = (): void => {
    navigate('/signup/phone-email');
  };

  const onEmailChange = (email: string): void => {
    setEmail(email);
    props.submitEmail({ email });
  };

  return (
    <StyledForm onSubmit={handleSubmit}>
      <StyledInput onChange={(e): void => onEmailChange(e.target.value)} placeholder={t('inputBanner.placeholder')} />
      <StyledButton>{t('inputBanner.buttonText')}</StyledButton>
    </StyledForm>
  );
};

const mapDispatchToProps: Props = {
  submitEmail: (props: EmailProps): any => submitEmail(props)
};

export default connect(null, mapDispatchToProps)(InputBanner);
