import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { useStyles } from './NeloBrandWrapper';

interface BrandFooterProps {
  color?: string;
}

const NeloBrandFooter = (props: BrandFooterProps): ReactElement => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid className={classes.bannerFooter} container>
      <Grid item sm={3} md={4} />
      <Grid item sm={6} md={4}>
        <Grid container className={classes.bannerFooterContainer} direction="row">
          <Link
            color={props?.color || 'white'}
            href="https://nelo.mx"
            target="_blank"
            className={classes.bannerFooterLinks}
          >
            {t('signup.copyright.year')}
          </Link>
          <Link
            color={props?.color || 'white'}
            underline="hover"
            href="https://www.nelo.mx/terms"
            target="_blank"
            className={classes.bannerFooterLinks}
          >
            {t('signup.terms.conditions')}
          </Link>
        </Grid>
      </Grid>
      <Grid item sm={3} md={4} />
    </Grid>
  );
};

export default NeloBrandFooter;
