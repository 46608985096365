import { makeStyles } from '@material-ui/core/styles';
import LoadingButton from '@material-ui/lab/LoadingButton';
import React, { ReactElement, useEffect } from 'react';

import { colors, spacing } from '../../styling';
import { theme } from './NeloBrandWrapper';

export const useStyles = makeStyles({
  button: {
    textTransform: 'none',
    paddingTop: spacing.spacing2x,
    paddingBottom: spacing.spacing2x,
    borderRadius: 100,
    marginTop: spacing.spacing3x,
    fontFamily: 'Nunito Sans',
    fontWeight: 600,
    fontSize: spacing.spacing2x,
    alignItems: 'center',
    textAlign: 'center',
    color: colors.white,
    background: colors.neloBlue,
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
      marginBottom: spacing.spacing4x
    }
  },
  validButton: {
    backgroundColor: colors.neloGreen,
    color: colors.neloDarkBlue,
    '& :hover': {
      color: colors.white
    },
    '& :focus': {
      color: colors.white
    },
    '& :active': {
      color: colors.white
    },
    '& :visited': {
      color: colors.white
    }
  }
});

interface NeloButtonProps {
  isLoading?: boolean;
  disabled?: boolean;
  text: string;
  onClick: () => void;
  className?: string;
  valid?: boolean;
  error?: boolean;
}

function NeloButton(props: NeloButtonProps): ReactElement {
  const classes = useStyles();

  useEffect(() => {
    const listener = (e: KeyboardEvent): void => {
      if (e.code === 'Enter' || e.code === 'NumpadEnter' || e.code === '13') {
        props.onClick();
      }
    };
    document.addEventListener('keydown', listener);
    return (): void => {
      document.removeEventListener('keydown', listener);
    };
  }, []);

  return (
    <LoadingButton
      pending={props.isLoading}
      disabled={props.disabled} // || !props.valid // This sets button to grey state
      onClick={props.onClick}
      sx={{
        '&:hover': {
          color: '#FFFFFF !important'
        }
      }}
      variant="contained"
      disableElevation
      className={`${props.valid && classes.validButton} ${classes.button} ${props.className}`}
    >
      {props.text}
    </LoadingButton>
  );
}

export default NeloButton;
