import 'swiper/css';

import styled from 'styled-components';
import SwiperCore from 'swiper';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import TestimonyCard from '../TestimonyCard';

SwiperCore.use([Navigation]);

const Container = styled.div`
  .blue {
    background: linear-gradient(180deg, #cdecff 0%, #b1d8ff 100%);
  }

  .rose {
    background: linear-gradient(180deg, #ffe4f6 0%, #ffb8e9 100%);
  }

  .green {
    background: linear-gradient(180deg, #f9ffdd 0%, #edff9e 100%);
  }
`;

const TestimonySlider = (): JSX.Element => {
  return (
    <div className="ctn mt-20">
      <Container>
        <Swiper loop={true} navigation={true} slidesPerView={1} spaceBetween={50} grabCursor={true}>
          <SwiperSlide>
            <TestimonyCard
              className="testimonial-card green"
              author="JONÁS R."
              testimony="¡Algo realmente espectacular! recomiendo Nelo, la mejor app para pedir prestado, pagar servicios y mucho más. 100% recomendada."
              color="green"
            />
          </SwiperSlide>
          <SwiperSlide>
            <TestimonyCard
              className="testimonial-card blue"
              author="LUIS ALFREDO T."
              testimony="De las mejores aplicaciones, neta que buena onda que se pueda confiar en una aplicación como esta. Gracias por ayudar a la gente a conseguir lo que quiere, sigan así, mucho éxito para ustedes."
              color="blue"
            />
          </SwiperSlide>
          <SwiperSlide>
            <TestimonyCard
              className="testimonial-card rose"
              author="VICTORIA S."
              testimony="Me encantó lo rápida que es la aplicación: los trámites son muy sencillos y la respuesta es inmediata, gracias Nelo."
              color="rose"
            />
          </SwiperSlide>
          <SwiperSlide>
            <TestimonyCard
              className="testimonial-card blue"
              author="CORDI CALIDAD N."
              testimony="Muy buena app: te facilita en recibos de luz y gas, préstamos y Mercado Libre que me encanta. No he tenido ningún problema con la app, los pagos son rápidos y se reflejan rápido, un 100 de 100."
              color="blue"
            />
          </SwiperSlide>
          <SwiperSlide>
            <TestimonyCard
              className="testimonial-card rose"
              author="CORDI CALIDAD N."
              testimony="La app está genial: muy sencilla y práctica. El trámite es rápido y corto. Estoy contento con esta app que me recomendaron y ahora yo también recomiendo. Gracias por la confianza."
              color="rose"
            />
          </SwiperSlide>
        </Swiper>
      </Container>
    </div>
  );
};

export default TestimonySlider;
