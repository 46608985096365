export const generateSiftJS = (sessionId: string, userId: string | null): string => {
  return `
      var _user_id = '${userId || ''}'; // Set to the user's ID, username, or email address, or '' if not yet known.
      var _session_id = '${sessionId}'; // Set to a unique session ID for the visitor's current browsing session.

      var _sift = window._sift = window._sift || [];
      _sift.push(['_setAccount', 'a2ad397969']);
      _sift.push(['_setUserId', _user_id]);
      _sift.push(['_setSessionId', _session_id]);
      _sift.push(['_trackPageview']);

    (function() {
      function ls() {
        var e = document.createElement('script');
        e.src = 'https://cdn.sift.com/s.js';
        document.body.appendChild(e);
      }
      if (window.attachEvent) {
        window.attachEvent('onload', ls);
      } else {
        window.addEventListener('load', ls, false);
      }
    })();`;
};
