import curp from './validateCurp';
import email from './validateEmail';
import empty from './validateEmpty';
import exists from './validateExists';
import name from './validateName';
import number from './validateNumber';
import pin from './validatePin';

const length = (value: string, length: number): boolean => value.length === length;

export default { pin, name, email, curp, number, empty, exists, length };
