import '../localization/i18n';

import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { EmptyAction, PayloadAction } from 'typesafe-actions';

import { AnalyticEvent } from '../analytics/AnalyticEvent';
import { emitAnalyticEventAction } from '../ducks/analytics';
import { ApplicationState, clearErrorMessage } from '../ducks/application';
import { NameEmailProps, SignupState, submitNameEmail, updateReferralData } from '../ducks/signup';
import { createVerificationCodeAction, MobileVerificationState, updatePhoneNumber } from '../ducks/verificationCode';
import useTrackReferrer from '../hooks/useTrackReferrer';
import { ReferralState } from '../interfaces/nelo-api/DeviceState';
import validate from '../util/inputs';
import Nelo from './Nelo';

// This component is bad. It appears as if an earlier version was for name+email but was redesigned to be phone+email.
// But there is a ton of cruft from the earlier implementation that was not removed.

interface DispatchProps {
  submitAnalyticEvent: (props: AnalyticEvent) => void;
  submitNameEmail: (props: NameEmailProps) => void;
  updatePhoneNumber: (number: string) => void;
  submitPhoneNumber: () => void;
  updateReferralData: (data: ReferralState) => void;
}

interface StateProps {
  name: string;
  email: string;
  isLoading: boolean;
  phoneNumberDisplay: string;
  errorMessage: string;
  referralData: ReferralState;
}

function useRunAfterUpdate(): (fn: any) => any {
  const afterPaintRef = useRef(null);
  React.useLayoutEffect(() => {
    if (afterPaintRef !== null && afterPaintRef.current) {
      // This is doing some nasty stuff, calling a function that's typed as `never`
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      afterPaintRef.current();
      afterPaintRef.current = null;
    }
  });
  const runAfterUpdate = (fn: any): void => (afterPaintRef.current = fn);
  return runAfterUpdate;
}

function countSpacesBeforeCursor(cursorIndex: number, text: string): number {
  let numSpaces = 0;
  text.split('').forEach((val, index) => {
    if (val === ' ' && index < cursorIndex) {
      numSpaces += 1;
    }
  });
  return numSpaces;
}

function handleTextChange(
  e: React.ChangeEvent<HTMLInputElement>,
  runAfterUpdate: any,
  props: DispatchProps & StateProps,
  setValid: (valid: boolean) => void
): boolean {
  const input = e.target;
  const text = input.value;
  const cursor = input.selectionEnd || 0;
  const numSpacesPriorToUpdate = countSpacesBeforeCursor(cursor, text);
  props.updatePhoneNumber(text);
  if (validate.number(text)) setValid(true);
  runAfterUpdate(() => {
    // The phone number formatter can programmatically add spaces in the text input.
    // The addition or removal of these spaces can change the position of the cursor.
    // To keep it in the same place the user expects it to be, we have to account for
    // the new spaces and calculate the new cursor position.
    // E.g. if you type "+19172^", this will get formatted to "+1 917^ 2" so your cursor
    // which was at position 6, right after the "2", now needs to be at position 8 in order
    // to still be after the "2".
    const numSpacesPostUpdate = countSpacesBeforeCursor(cursor, input.value);
    const newCursorPosition = (cursor || 0) + (numSpacesPostUpdate - numSpacesPriorToUpdate);
    input.selectionStart = newCursorPosition;
    input.selectionEnd = newCursorPosition;
  });
  return false;
}

function NameEntry(props: DispatchProps & StateProps): ReactElement {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [name, setName] = useState<string>(props.name || '');
  const [email, setEmail] = useState<string>(props.email || '');
  const [validName, setValidName] = useState<boolean>(false);
  const [validEmail, setValidEmail] = useState<boolean>(false);

  const runAfterUpdate = useRunAfterUpdate();
  const [phoneNumber, setPhoneNumber] = useState<string>(props.phoneNumberDisplay);
  const [typed, setTyped] = useState<boolean>(false);
  const [valid, setValid] = useState<boolean>(
    props?.phoneNumberDisplay ? validate.number(props.phoneNumberDisplay) : false
  );

  useTrackReferrer(props.updateReferralData, props.referralData);

  useEffect(() => {
    if (validate.name(name, true)) {
      setValidName(true);
    }
    if (name.length === 0) {
      setValidName(false);
    }
    if (validate.email(email)) {
      setValidEmail(true);
    }
  }, [name, email]);

  const handleOnClick = (): void => {
    clearErrorMessage();
    if (valid && validEmail) {
      props.submitAnalyticEvent({ name: 'WEB_SIGNUP_SUBMIT_NAME_EMAIL', params: { email, name } });
      props.submitNameEmail({ name, email });
      if (valid) {
        globalThis.userEmail = email;
        globalThis.userPhoneNumber = phoneNumber;
        props.submitPhoneNumber();
        setTimeout(() => {
          if (!props.errorMessage) {
            navigate('/signup/confirm-code');
          }
        }, 1000);
      }
    }
  };

  useEffect((): void => {
    const urlParams = new URLSearchParams(window.location.search);
    const queryParamsEmail = urlParams.get('email');
    if (!email && !!queryParamsEmail) {
      setEmail(queryParamsEmail);
    }
  }, []);

  return (
    <Nelo.BrandWrapper
      title={t('signup.name.title')}
      subtitle={t('signup.name.subtitle')}
      progress={1}
      onClick={handleOnClick}
      hideBackButton
    >
      <Nelo.TextField
        name="phone"
        autoFocus
        label={t('signup.phone.label')}
        onChange={(e: React.ChangeEvent<HTMLInputElement>): boolean => {
          if (e.target.value.length > 8) {
            setTyped(true);
          }
          return handleTextChange(e, runAfterUpdate, props, setValid);
        }}
        value={props.phoneNumberDisplay}
        setValue={setPhoneNumber}
        valid={typed ? valid : true}
      />

      <Nelo.TextField
        name="email"
        label={t('signup.email.label')}
        onChange={(e: React.ChangeEvent<HTMLInputElement>): void => setEmail(e.target.value)}
        setValue={setEmail}
        value={email}
        valid={validEmail}
      />
      <Nelo.Button
        disabled={!valid || !validEmail}
        isLoading={props.isLoading}
        text={t('signup.name.buttonText')}
        onClick={handleOnClick}
        valid={true}
      />
    </Nelo.BrandWrapper>
  );
}

const mapDispatchToProps = {
  submitNameEmail: (props: NameEmailProps): any => submitNameEmail(props),
  submitAnalyticEvent: (props: AnalyticEvent): any => emitAnalyticEventAction(props),
  updatePhoneNumber: (number: string): PayloadAction<string, string> => updatePhoneNumber(number),
  updateReferralData: (data: ReferralState): any => updateReferralData(data),
  submitPhoneNumber: (): EmptyAction<'mobileVerification/CREATE_VERIFICATION_CODE'> => createVerificationCodeAction()
};

const mapStateToProps = ({
  signup,
  application,
  verificationCode
}: {
  signup: SignupState;
  application: ApplicationState;
  verificationCode: MobileVerificationState;
}): StateProps => ({
  name: signup.name,
  email: signup.email,
  isLoading: application.isLoading && !application.errorMessage,
  phoneNumberDisplay: verificationCode.phoneNumberDisplay,
  errorMessage: application.errorMessage,
  referralData: signup.referralData
});

export default connect(mapStateToProps, mapDispatchToProps)(NameEntry);
