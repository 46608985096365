import styled from 'styled-components';

import Checkmark from '../../Nelo/img/checkmark-benefits.png';

const RoundedBorder = styled.div`
  position: relative;

  &:before {
    content: '';
    position: absolute;
    height: 70px;
    top: -70px;
    left: 0;
    right: 0;
    background-color: white;
    border-radius: 32px 32px 0 0;
    z-index: 20;
  }

  @media screen and (min-width: 1024px) {
    &:before {
      content: '';
      position: absolute;
      height: 80px;
      top: -80px;
      left: 0;
      right: 0;
      background-color: white;
      border-radius: 32px 32px 0 0;
      z-index: 20;
    }
  }
`;

const CreditChecks = (): JSX.Element => {
  return (
    <RoundedBorder>
      <div className="ctn bg-white pt-[-1px] mb-28">
        <h2 className="text-center text-darkGrey tracking-[3px] uppecase">CRÉDITO PARA TI</h2>
        <div className="flex justify-center">
          <ul className="grid mt-12 lg:mt-24 grid-cols-1 md:grid-cols-2">
            <li className="flex items-center my-2 md:my-4">
              <img className="flex-shrink-0 w-10 h-10" width={40} height={40} alt="" src={Checkmark} aria-hidden />
              <p className="ml-4 font-manrope text-[22px] lg:text-[28px]">Aprobación inmediata</p>
            </li>
            <li className="flex items-center my-2 md:my-4">
              <img className="flex-shrink-0 w-10 h-10" width={40} height={40} alt="" src={Checkmark} aria-hidden />
              <p className="ml-4 font-manrope text-[22px] lg:text-[28px]">Tu crédito aumenta</p>
            </li>
            <li className="flex items-center my-2 md:my-4">
              <img className="flex-shrink-0 w-10 h-10" width={40} height={40} alt="" src={Checkmark} aria-hidden />
              <p className="ml-4 font-manrope text-[22px] lg:text-[28px]">Paga montos fijos y claros</p>
            </li>
            <li className="flex items-center my-2 md:my-4">
              <img className="flex-shrink-0 w-10 h-10" width={40} height={40} alt="" src={Checkmark} aria-hidden />
              <p className="ml-4 font-manrope text-[22px] lg:text-[28px]">Mejora tu historial crediticio</p>
            </li>
          </ul>
        </div>
      </div>
    </RoundedBorder>
  );
};

export default CreditChecks;
