export const colors = {
  primary: '#067BF2',
  secondary: '#AAAAAA',
  accent: '#EEEEEE',
  secondaryAccent: '#444444',
  black: '#27323D',
  white: '#FFFFFF',
  neloGreen: '#E1FF58',
  neloBlue: '#067BF2',
  neloDarkBlue: '#067BF2'
};

const BASE_SIZING_UNIT = 8;

export const spacing = {
  spacing1x: BASE_SIZING_UNIT,
  spacing2x: BASE_SIZING_UNIT * 2,
  spacing3x: BASE_SIZING_UNIT * 3,
  spacing4x: BASE_SIZING_UNIT * 4,
  spacing5x: BASE_SIZING_UNIT * 5,
  spacing6x: BASE_SIZING_UNIT * 6,
  spacing7x: BASE_SIZING_UNIT * 7,
  spacing8x: BASE_SIZING_UNIT * 8,
  spacing9x: BASE_SIZING_UNIT * 9,
  spacing10x: BASE_SIZING_UNIT * 10,
  spacingHalfx: BASE_SIZING_UNIT * 0.5,
  spacingOneAndHalfx: BASE_SIZING_UNIT * 1.5,
  gutter: BASE_SIZING_UNIT * 2
};
