import { useEffect } from 'react';

const useHandleCopyPaste = (window: any, trackCopyPaste: (fieldName: string, path: string) => void): void => {
  useEffect(() => {
    const handlePasteAnywhere = (event: any): void => {
      trackCopyPaste(event.target.name, window.location.href);
    };
    window.addEventListener('paste', handlePasteAnywhere);
    return () => {
      window.removeEventListener('paste', handlePasteAnywhere);
    };
  }, []);
};

export default useHandleCopyPaste;
