import FingerprintJS from '@fingerprintjs/fingerprintjs';

import packageJson from '../../package.json';

export async function getDeviceHeaders(): Promise<{ [key: string]: string }> {
  const fp = await FingerprintJS.load();
  const result = await fp.get();
  const visitorId = result.visitorId;
  return {
    'x-app-version': packageJson.version,
    'x-app-version-code': '1',
    'x-device-platform': 'web',
    'x-device-timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
    'x-device-id': visitorId,
    'x-device-manufacturer': navigator.vendor,
    'x-device-model': navigator.platform
  };
}

export async function getHeaders(): Promise<Headers> {
  const deviceHeaders = await getDeviceHeaders();
  return new Headers({
    Accept: 'application/json',
    ...deviceHeaders
  });
}

export function getAuthorizationHeader(accessToken: string | null): string | null {
  return accessToken ? `Bearer ${accessToken}` : null;
}
