import { StrictEffect } from '@redux-saga/types';
import { AsYouType, CountryCode, E164Number } from 'libphonenumber-js';
import { all, call, put, SagaGenerator, select, takeEvery } from 'typed-redux-saga';
import { createAction, createReducer, PayloadAction } from 'typesafe-actions';

import NeloApiClient from '../clients/NeloApiClient';
import { InvalidApplicationStateError } from '../errors/InvalidApplicationState';
import { ApiError } from '../errors/NeloApi';
import PhoneNumber from '../interfaces/nelo-api/PhoneNumber';
import { ConfirmVerificationCodeResponse } from '../interfaces/nelo-api/VerificationCode';
import i18next from '../localization/i18n';
import { doNeloApiRequest, doNeloApiRequestWithResponse, safeCall } from '../util/neloApiRequest';
import { emitAnalyticEventAction } from './analytics';
import { updateErrorMessage } from './application';
import { updateAuthState } from './auth';

export const updatePhoneNumber = createAction('mobileVerification/UPDATE_PHONE_NUMBER')<string>();
export const createVerificationCodeAction = createAction('mobileVerification/CREATE_VERIFICATION_CODE')();
export const updateVerificationCode = createAction('mobileVerification/UPDATE_VERIFICATION_CODE')<string>();
export const submitVerificationCodeAction = createAction('mobileVerification/SUBMIT_VERIFICATION_CODE')();
export const completedMobileVerification = createAction('mobileVerification/COMPLETED')();

export interface MobileVerificationState {
  countryIso2: CountryCode;
  phoneNumberDisplay: string;
  phoneNumberE164: E164Number | null;
  isPhoneNumberValid: boolean;
  verificationCode: string;
  isVerificationCodeValid: boolean;
}

export const initialState: MobileVerificationState = {
  countryIso2: 'MX', // TODO: Get country code input
  phoneNumberDisplay: '+52 ',
  phoneNumberE164: null,
  isPhoneNumberValid: false,
  verificationCode: '',
  isVerificationCodeValid: false
};

const getPhoneNumber = ({ verificationCode }: { verificationCode: MobileVerificationState }): PhoneNumber | null => {
  const { phoneNumberE164, countryIso2 } = verificationCode;
  if (phoneNumberE164 && countryIso2) {
    return {
      number: phoneNumberE164.toString(),
      countryIso2
    };
  }
  return null;
};

const getState = ({ verificationCode }: { verificationCode: MobileVerificationState }): MobileVerificationState =>
  verificationCode;

const setPhoneNumber = (
  state: MobileVerificationState,
  action: PayloadAction<string, string>
): MobileVerificationState => {
  const input = action.payload;
  const asYouTypeFormatter = new AsYouType(state.countryIso2);
  const formattedNumber = asYouTypeFormatter.input(input);
  const parsedNumber = asYouTypeFormatter.getNumber();
  return {
    ...state,
    phoneNumberDisplay: formattedNumber || '+',
    isPhoneNumberValid: (parsedNumber && parsedNumber.isValid()) || false,
    phoneNumberE164: (parsedNumber && parsedNumber.number) || null,
    countryIso2: parsedNumber?.country ?? 'MX'
  };
};

const setVerificationCode = (
  state: MobileVerificationState,
  action: PayloadAction<string, string>
): MobileVerificationState => ({
  ...state,
  verificationCode: action.payload,
  isVerificationCodeValid: action.payload.trim().length === 5
});

const resetVerificationCode = (state: MobileVerificationState): MobileVerificationState => ({
  ...state,
  verificationCode: '',
  isVerificationCodeValid: false
});

export default createReducer(initialState)
  .handleAction(updatePhoneNumber, setPhoneNumber)
  .handleAction(updateVerificationCode, setVerificationCode)
  .handleAction(createVerificationCodeAction, resetVerificationCode)
  .handleAction(completedMobileVerification, resetVerificationCode);

function* createVerificationCode(): Generator<StrictEffect, void, void> {
  const smsOnly = false;
  const phoneNumber = yield* select(getPhoneNumber);
  if (!phoneNumber) {
    yield* put(
      updateErrorMessage({
        errorMessage: i18next.t('signup.error.invalidNumber')
      })
    );
    return;
  }
  try {
    yield* call<any, any>(
      doNeloApiRequest,
      NeloApiClient.createMobileVerification.bind(NeloApiClient, {
        phoneNumber,
        smsOnly
      })
    );
  } catch (error) {
    if (error instanceof ApiError) {
      yield* put(updateErrorMessage({ errorMessage: error.getMessage() }));
    } else {
      yield* put(updateErrorMessage({ errorMessage: i18next.t('errors.unexpected') }));
    }
  }
}

function* submitVerificationCode(): Generator<StrictEffect, void, void> {
  const phoneNumber = yield* select(getPhoneNumber);

  if (!phoneNumber) {
    throw new InvalidApplicationStateError('Invalid null phone number');
  }

  const state = yield* select(getState);
  let responseBody;
  try {
    responseBody = yield* call<any, any>(
      doNeloApiRequestWithResponse,
      NeloApiClient.confirmMobileVerification.bind(NeloApiClient, {
        phoneNumber,
        code: state.verificationCode
      })
    );
  } catch (error) {
    if (error instanceof ApiError) {
      yield* put(updateErrorMessage({ errorMessage: error.getMessage() }));
    } else {
      yield* put(updateErrorMessage({ errorMessage: i18next.t('errors.unexpected') }));
    }
    return;
  }
  const { authAction, token } = responseBody as ConfirmVerificationCodeResponse;
  yield* put(
    updateAuthState({
      accessToken: token,
      authAction,
      userUuid: ''
    })
  );
  if (authAction === 'LOGIN') {
    yield* put(
      emitAnalyticEventAction({
        name: 'WEB_SIGNUP_VERIFICATION_CODE_LOGGED_IN',
        params: { phoneNumber: phoneNumber.toString() }
      })
    );
  } else if (authAction === 'SIGNUP') {
    yield* put(
      emitAnalyticEventAction({
        name: 'WEB_SIGNUP_VERIFICATION_CODE_SIGNUP',
        params: { phoneNumber: phoneNumber.toString() }
      })
    );
  } else {
    throw new InvalidApplicationStateError(`Invalid authAction. Was ${authAction}`);
  }
  yield* put(completedMobileVerification());
}

export function* verificationCodeSaga(): SagaGenerator<void> {
  yield* all([
    takeEvery(createVerificationCodeAction, safeCall, createVerificationCode),
    takeEvery(submitVerificationCodeAction, safeCall, submitVerificationCode)
  ]);
}
