import PhoneGroup from '../../Nelo/img/phonegroup.png';
interface PreFooterI {
  text: string;
  buttonLabel: string;
  href: string;
}

const PreFooter = ({ text, buttonLabel, href }: PreFooterI): JSX.Element => {
  const roseClass = 'bg-gradient-to-br from-[#FF6AD0] to-[#FF5947]';
  const className = 'ctn mb-20 mt-20 md:mb-40 rounded-[40px] flex flex-col lg:flex-row justify-center p-8 items-center';

  return (
    <div className={`${className} ${roseClass}`}>
      <img src={PhoneGroup} alt="imagen de dos celulares con la app de Nelo" width={400} height={350} />
      <div className="flex flex-col items-start md:max-w-[400px] lg:mx-16 md:mx-12">
        <p className="font-light text-[42px] leading-[52px] text-left text-white mb-[36px]">{text}</p>
        <a className="mb-8 bg-white text-primary py-4 px-8 rounded-[34px] font-semibold" href={href}>
          {buttonLabel}
        </a>
      </div>
    </div>
  );
};

export default PreFooter;
