import '../localization/i18n';

import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { EmptyAction, PayloadAction } from 'typesafe-actions';

import { ApplicationProps, ApplicationState } from '../ducks/application';
import { AuthState } from '../ducks/auth';
import {
  MobileVerificationState,
  submitVerificationCodeAction,
  updateVerificationCode
} from '../ducks/verificationCode';
import { AuthAction } from '../interfaces/nelo-api/AuthAction';
import Nelo from './Nelo';

interface DispatchProps {
  submitVerificationCode: () => void;
  updateVerificationCode: (code: string) => void;
}

interface StateProps extends ApplicationProps {
  verificationCode: string;
  authAction: AuthAction | null;
}

function MobileVerificationEntry(props: DispatchProps & StateProps): ReactElement {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { verificationCode, updateVerificationCode } = props;
  const [code, setCode] = useState<string>('');
  const [valid, setValid] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  useEffect(() => {
    if (code.length === 5) {
      setValid(true);
    }
  }, [code]);

  useEffect(() => {
    if (!valid || !submitted) return;
    if (props.authAction === 'LOGIN') {
      navigate('/signup/welcome-back');
    }
    if (props.authAction === 'SIGNUP') {
      navigate('/signup/curp-generator');
    }
  }, [props.authAction, valid, submitted]);

  const handleOnClick = (): void => {
    setSubmitted(true);
    props.submitVerificationCode();
  };

  return (
    <Nelo.BrandWrapper
      title={t('signup.code.title')}
      subtitle={t('signup.code.subtitle')}
      description={t('signup.name.description')}
      onClick={handleOnClick}
      progress={2}
    >
      <Nelo.TextField
        name="Verification Code"
        autoFocus
        label={t('signup.code.label')}
        onChange={(e: React.ChangeEvent<HTMLInputElement>): void => updateVerificationCode(e.target.value)}
        value={verificationCode}
        setValue={setCode}
        valid={valid}
      />
      <Nelo.Button
        disabled={!valid}
        isLoading={props.isLoading}
        text={t('signup.code.buttonText')}
        onClick={handleOnClick}
        valid={valid}
      />
    </Nelo.BrandWrapper>
  );
}

const mapDispatchToProps = {
  updateVerificationCode: (code: string): PayloadAction<string, string> => updateVerificationCode(code),
  submitVerificationCode: (): EmptyAction<'mobileVerification/SUBMIT_VERIFICATION_CODE'> =>
    submitVerificationCodeAction()
};

const mapStateToProps = ({
  verificationCode,
  application,
  auth
}: {
  verificationCode: MobileVerificationState;
  application: ApplicationState;
  auth: AuthState;
}): StateProps => ({
  verificationCode: verificationCode.verificationCode,
  isLoading: application.isLoading && !application.errorMessage,
  authAction: auth.authAction
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileVerificationEntry);
