import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import React, { ReactElement, useEffect } from 'react';

import { colors, spacing } from '../../styling';
import Check from '../../svg/check.svg';

export const useStyles = makeStyles({
  error: {
    borderColor: '#FF4344 !important',
    border: '2px solid #FF4344 !important'
  },
  adornmentIcon: {
    display: 'grid',
    justifyContent: 'center',
    alignItems: 'center'
  },
  smallTextEntry: {
    width: '48%'
  },
  addressTextEntry: {
    border: 'none !important'
  },
  closeIcon: {
    color: 'red',
    display: 'grid',
    justifyContent: 'center',
    alignItems: 'center'
  },
  textEntry: {
    marginBottom: spacing.spacing2x,
    background: colors.white,
    borderShadow: 'none',
    borderRadius: spacing.spacing1x,
    '& .MuiInput-underline:after': {
      border: `3px solid ${colors.neloBlue}`
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'none'
      },
      '&:hover fieldset': {
        border: `2.5px solid ${colors.neloBlue}`
      },
      '&.Mui-focused fieldset': {
        border: `2.5px solid ${colors.neloBlue}`
      }
    }
  }
});

interface NeloTextFieldProps {
  valid?: boolean;
  type?: React.InputHTMLAttributes<unknown>['type'];
  required?: boolean;
  error?: boolean;
  helperText?: any;
  placeholder?: string;
  disabled?: boolean;
  value: string;
  label?: string | React.ReactNode;
  id?: string;
  setValue?: (value: any) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  name: string;
  inputProps?: any;
  autocomplete?: string;
  hideAdornment?: boolean;
  startAdornment?: string;
  autoFocus?: boolean;
}

export const TextInputAdornment = ({ value, valid }: { value: string; valid?: boolean }): ReactElement => {
  const classes = useStyles();
  return (
    <InputAdornment position="end">
      {valid ? (
        <img src={Check} className={classes.adornmentIcon} />
      ) : value !== '' && !valid ? (
        <CloseIcon className={classes.closeIcon} />
      ) : null}
    </InputAdornment>
  );
};

function NeloTextField(props: NeloTextFieldProps): ReactElement {
  const classes = useStyles();
  const {
    autoFocus,
    className,
    autocomplete,
    value,
    setValue,
    type,
    helperText,
    required,
    placeholder,
    label,
    id,
    valid,
    error,
    onChange,
    hideAdornment,
    startAdornment,
    name
  } = props;

  useEffect((): void => {
    setValue && setValue(value);
  }, [value]);

  return (
    <TextField
      required={required}
      helperText={helperText}
      error={error || (!valid && props.value !== '')}
      name={name}
      autoFocus={autoFocus}
      className={`${error && value !== '' && classes.error} ${className ? className : classes.textEntry}`}
      placeholder={placeholder}
      id={id || 'standard-basic'}
      type={type || 'text'}
      label={label}
      value={value}
      autoComplete={autocomplete}
      onChange={onChange}
      InputProps={{
        onClick: (): void => setValue && setValue(''),
        startAdornment: startAdornment && <InputAdornment position="start">{startAdornment}</InputAdornment>,
        endAdornment: !hideAdornment && <TextInputAdornment value={value} valid={valid} />,
        readOnly: hideAdornment,
        ...props.inputProps
      }}
    />
  );
}

export default NeloTextField;
