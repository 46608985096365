import '../localization/i18n';

import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PayloadAction } from 'typesafe-actions';

import { MexicanState, MexicanStates } from '../constants/mexicanstates';
import { ApplicationProps } from '../ducks/application';
import {
  CurpDetails,
  CurpGender,
  GeneratedCurpResponse,
  getGenerateCurp,
  SignupState,
  UpdateCurpDetailsPayload,
  updateCurpDetailsPayload
} from '../ducks/signup';
import { isEmpty } from '../util/isEmpty';
import Nelo from './Nelo';
import { TextInputAdornment } from './Nelo/NeloTextField';

interface SelectElement {
  value: unknown;
}

const getLast80Years = (currentYear: number = new Date().getFullYear()): number[] => {
  return Array.from({ length: 80 }, (_, i) => currentYear - i);
};

const getDaysOfMonth = (): number[] => {
  return Array.from({ length: 31 }, (_, i) => i + 1);
};

const getMonths = (): number[] => {
  return Array.from({ length: 12 }, (_, i) => i + 1);
};

interface DispatchProps {
  setCurpDetails: (payload: UpdateCurpDetailsPayload) => void;
  generateCurp: (payload: UpdateCurpDetailsPayload) => void;
}

interface StateProps extends ApplicationProps {
  curpDetails: CurpDetails;
  generatedCurpResponse: GeneratedCurpResponse | null;
}

function CurpGenerator(props: DispatchProps & StateProps): ReactElement {
  const classes = Nelo.useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const { curpDetails, generatedCurpResponse } = props;

  const submitCurpGenerate = (): void => {
    console.log(curpDetails);
    setHasSubmitted(true);
    props.generateCurp(curpDetails);
  };

  useEffect((): void => {
    if (generatedCurpResponse) {
      navigate('/signup/confirm-curp');
    }
  }, [generatedCurpResponse]);

  // TODO: most of these inputs have the same name - we should change that or remove them, but need to confirm
  // it doesn't break anything

  return (
    <Nelo.BrandWrapper
      title={t('signup.curpGenerator.title')}
      subtitle={t('signup.curpGenerator.subtitle')}
      progress={3}
    >
      <Nelo.TextField
        name="First Name"
        required
        error={hasSubmitted && isEmpty(curpDetails.name)}
        valid={!isEmpty(curpDetails.name)}
        className={classes.textEntry}
        label={t('signup.curpGenerator.firstName')}
        setValue={(val: string): void => props.setCurpDetails({ name: val })}
        onChange={(e: React.ChangeEvent<HTMLInputElement>): void => props.setCurpDetails({ name: e.target.value })}
        value={curpDetails.name || ''}
      />

      <Nelo.TextField
        name="Paternal last name"
        required
        error={hasSubmitted && isEmpty(curpDetails.paternalLastName)}
        valid={!isEmpty(curpDetails.paternalLastName)}
        className={classes.textEntry}
        label={t('signup.curpGenerator.paternalLastName')}
        setValue={(val: string): void => props.setCurpDetails({ paternalLastName: val })}
        onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
          props.setCurpDetails({ paternalLastName: e.target.value })
        }
        value={curpDetails.paternalLastName || ''}
      />

      <Nelo.TextField
        name="Maternal Last Name"
        required
        error={hasSubmitted && isEmpty(curpDetails.maternalLastName)}
        valid={!isEmpty(curpDetails.maternalLastName)}
        className={classes.textEntry}
        label={t('signup.curpGenerator.maternalLastName')}
        setValue={(val: string): void => props.setCurpDetails({ maternalLastName: val })}
        onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
          props.setCurpDetails({ maternalLastName: e.target.value })
        }
        value={curpDetails.maternalLastName || ''}
      />

      <Grid container justifyContent="space-between" direction="row">
        <FormControl required variant="outlined" className={`${classes.textEntry} ${classes.smallTextEntry}`}>
          <InputLabel htmlFor="birth-day-input">{t('signup.curpGenerator.birthDay')}</InputLabel>
          <Select
            name="Birth Day"
            native
            error={hasSubmitted && isEmpty(curpDetails.dayOfBirth?.toString())}
            value={curpDetails.dayOfBirth}
            onChange={(e: React.ChangeEvent<SelectElement>): void =>
              props.setCurpDetails({ dayOfBirth: e.target.value as number })
            }
            label={t('signup.curpGenerator.birthDay')}
            inputProps={{
              name: 'birth-day',
              id: 'birth-day-input',
              endAdornment: (
                <TextInputAdornment
                  value={curpDetails.dayOfBirth?.toString() || ''}
                  valid={!isEmpty(curpDetails.dayOfBirth?.toString())}
                />
              )
            }}
          >
            <option aria-label="None" value="" />
            {getDaysOfMonth().map((state: number) => (
              <option key={state} value={state}>
                {state}
              </option>
            ))}
          </Select>
        </FormControl>

        <FormControl required variant="outlined" className={`${classes.textEntry} ${classes.smallTextEntry}`}>
          <InputLabel htmlFor="birth-month-input">{t('signup.curpGenerator.birthMonth')}</InputLabel>
          <Select
            name="Month of Birth"
            native
            error={hasSubmitted && isEmpty(curpDetails.monthOfBirth)}
            value={curpDetails.monthOfBirth}
            onChange={(e: React.ChangeEvent<SelectElement>): void =>
              props.setCurpDetails({ monthOfBirth: e.target.value as number })
            }
            label={t('signup.curpGenerator.birthMonth')}
            inputProps={{
              name: 'birth-month',
              id: 'birth-month-input',
              endAdornment: (
                <TextInputAdornment
                  value={curpDetails.monthOfBirth?.toString() || ''}
                  valid={!isEmpty(curpDetails.monthOfBirth?.toString())}
                />
              )
            }}
          >
            <option aria-label="None" value="" />
            {getMonths().map((state: number) => (
              <option key={state} value={state}>
                {state}
              </option>
            ))}
          </Select>
        </FormControl>

        <FormControl required variant="outlined" className={`${classes.textEntry} ${classes.smallTextEntry}`}>
          <InputLabel htmlFor="birth-year-input">{t('signup.curpGenerator.birthYear')}</InputLabel>
          <Select
            name="Birth Year"
            native
            error={hasSubmitted && isEmpty(curpDetails.yearOfBirth)}
            value={curpDetails.yearOfBirth}
            onChange={(e: React.ChangeEvent<SelectElement>): void =>
              props.setCurpDetails({ yearOfBirth: e.target.value as number })
            }
            label={t('signup.curpGenerator.birthYear')}
            inputProps={{
              name: 'birth-year',
              id: 'birth-year-input',
              endAdornment: (
                <TextInputAdornment
                  value={curpDetails.yearOfBirth?.toString() || ''}
                  valid={!isEmpty(curpDetails.yearOfBirth?.toString())}
                />
              )
            }}
          >
            <option aria-label="None" value="" />
            {getLast80Years().map((state: number) => (
              <option key={state} value={state}>
                {state}
              </option>
            ))}
          </Select>
        </FormControl>

        <FormControl required variant="outlined" className={`${classes.textEntry} ${classes.smallTextEntry}`}>
          <InputLabel htmlFor="gender-input">{t('signup.curpGenerator.gender')}</InputLabel>
          <Select
            name="Gender"
            native
            error={hasSubmitted && isEmpty(curpDetails.gender)}
            value={curpDetails.gender}
            onChange={(e: React.ChangeEvent<SelectElement>): void =>
              props.setCurpDetails({ gender: e.target.value as CurpGender })
            }
            label={t('signup.curpGenerator.gender')}
            inputProps={{
              name: 'gender',
              id: 'gender-input',
              endAdornment: (
                <TextInputAdornment
                  value={curpDetails.gender?.toString() || ''}
                  valid={!isEmpty(curpDetails.gender?.toString())}
                />
              )
            }}
          >
            <option aria-label="None" value="" />
            {['M', 'F'].map((state: string) => (
              <option key={state} value={state}>
                {state}
              </option>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <FormControl required variant="outlined" className={`${classes.textEntry}`}>
        <InputLabel htmlFor="birth-state-input">{t('signup.curpGenerator.birthState')}</InputLabel>
        <Select
          name="Birth State"
          native
          error={hasSubmitted && isEmpty(curpDetails.stateOfBirth)}
          value={curpDetails.stateOfBirth}
          onChange={(e: React.ChangeEvent<SelectElement>): void =>
            props.setCurpDetails({ stateOfBirth: e.target.value as MexicanState })
          }
          label={t('signup.curpGenerator.birthState')}
          inputProps={{
            name: 'birth-state',
            id: 'birth-state-input',
            endAdornment: (
              <TextInputAdornment
                value={curpDetails.stateOfBirth?.toString() || ''}
                valid={!isEmpty(curpDetails.stateOfBirth?.toString())}
              />
            )
          }}
        >
          <option aria-label="None" value="" />
          {MexicanStates.map((state: string) => (
            <option key={state} value={state}>
              {state}
            </option>
          ))}
        </Select>
      </FormControl>

      <Nelo.Button
        disabled={
          isEmpty(curpDetails.dayOfBirth) ||
          isEmpty(curpDetails.monthOfBirth) ||
          isEmpty(curpDetails.yearOfBirth) ||
          isEmpty(curpDetails.name) ||
          isEmpty(curpDetails.maternalLastName) ||
          isEmpty(curpDetails.paternalLastName) ||
          isEmpty(curpDetails.gender) ||
          isEmpty(curpDetails.stateOfBirth)
        }
        isLoading={props.isLoading}
        text={t('signup.curp.buttonText')}
        onClick={submitCurpGenerate}
      />
    </Nelo.BrandWrapper>
  );
}

const mapDispatchToProps = {
  setCurpDetails: (payload: UpdateCurpDetailsPayload): PayloadAction<string, CurpDetails> => {
    if (payload.name) {
      globalThis.userFirstName = payload.name;
    }
    if (payload.paternalLastName) {
      globalThis.userLastName = payload.paternalLastName;
    }
    return updateCurpDetailsPayload(payload);
  },
  generateCurp: (payload: UpdateCurpDetailsPayload): PayloadAction<string, CurpDetails> => getGenerateCurp(payload)
};

const mapStateToProps = ({ signup }: { signup: SignupState }): StateProps => ({
  isLoading: signup.isLoading,
  curpDetails: signup.curpDetails,
  generatedCurpResponse: signup.generatedCurpResponse
});

export default connect(mapStateToProps, mapDispatchToProps)(CurpGenerator);
