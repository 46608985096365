import '../localization/i18n';

import { FormControl, InputLabel, Select } from '@material-ui/core';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PayloadAction } from 'typesafe-actions';

import { AnalyticEvent } from '../analytics/AnalyticEvent';
import Occupations from '../constants/occupations';
import { emitAnalyticEventAction } from '../ducks/analytics';
import { ApplicationState } from '../ducks/application';
import { SignupState, updateIncome, updateOccupation } from '../ducks/signup';
import Nelo from './Nelo';

interface DispatchProps {
  updateIncome: (value: number) => void;
  emitEvent: (props: AnalyticEvent) => void;
  updateOccupation: (occupation: string) => void;
}

interface StateProps {
  income: number;
  isLoading: boolean;
  errorMessage?: string;
  occupation: string;
}

interface SelectElement {
  value: unknown;
}

function OccupationAndIncome(props: DispatchProps & StateProps): ReactElement {
  const { t } = useTranslation();
  const { income, isLoading, updateIncome, errorMessage } = props;
  const navigate = useNavigate();
  const [valid, setValid] = useState<boolean>(income > 0);
  const classes = Nelo.useStyles();

  const handleOnClick = (): false | void => {
    if (!errorMessage && valid) {
      props.emitEvent({ name: 'WEB_SIGNUP_INCOME', params: { verify: 'true' } });
      navigate('/signup/address-entry');
    }
  };

  return (
    <Nelo.BrandWrapper
      title={t('signup.income.title')}
      subtitle={t('signup.income.subtitle')}
      onClick={handleOnClick}
      progress={4}
    >
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel
          htmlFor="occupation-entry"
          sx={{
            mb: 16
          }}
        >
          {t('signup.curp.occupation.label')}
        </InputLabel>
        <Select
          native
          autoFocus
          value={props.occupation}
          onChange={(e: React.ChangeEvent<SelectElement>): void => {
            props.updateOccupation(e.target.value as string);
          }}
          sx={{
            mb: 2
          }}
          label={<span>t('signup.curp.occupation.label')</span>}
          inputProps={{
            name: 'occupation',
            id: 'occupation-entry'
          }}
        >
          <option aria-label="None" value="" />
          {Occupations.map(occupation => (
            <option key={occupation.value} value={occupation.value}>
              {occupation.label}
            </option>
          ))}
        </Select>
      </FormControl>

      <Nelo.TextField
        name="income"
        startAdornment={'$'}
        label={t('signup.income.label')}
        onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
          const value = e.target.value;
          const number = parseInt(value);
          setValid(number > 0);
          if (number) {
            updateIncome(number);
          } else {
            updateIncome(0);
          }
        }}
        value={income > 0 ? income.toString() : ''}
        valid={valid}
      />
      <Nelo.Button isLoading={isLoading} text={t('signup.income.buttonText')} onClick={handleOnClick} valid={valid} />
    </Nelo.BrandWrapper>
  );
}

const mapDispatchToProps = {
  updateIncome: (value: number): any => updateIncome(value),
  emitEvent: (props: AnalyticEvent): any => emitAnalyticEventAction(props),
  updateOccupation: (occupation: string): PayloadAction<string, string> => updateOccupation(occupation)
};

const mapStateToProps = ({
  signup,
  application
}: {
  signup: SignupState;
  application: ApplicationState;
}): StateProps => ({
  income: signup.income,
  errorMessage: application.errorMessage,
  occupation: signup.occupation,
  isLoading: application.isLoading && !application.errorMessage
});

export default connect(mapStateToProps, mapDispatchToProps)(OccupationAndIncome);
