import styled from 'styled-components';

import ImageThree from '../../Nelo/img/VipBackground.png';
import ImageTwo from '../../Nelo/img/VipInfoMobile.png';
import ImageOne from '../../Nelo/img/VipRegisterBackground.png';

const StyledBoxOne = styled.div`
  display: none;

  @media screen and (min-width: 1024px) {
    display: block;
    background-image: url(${ImageOne});
    position: absolute;
    top: 80px;
    left: 0;
    z-index: 1;
    padding: 60px 140px 60px 40px;
  }
  width: 589px;
  height: 800px;
`;

const StyledBoxTwo = styled.div`
  background-image: url(${ImageTwo});
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  padding: 40px 40px 40px 30px;
  width: 100%;
  min-height: 700px;
  border-radius: 40px;

  @media screen and (min-width: 1024px) {
    background-image: url(${ImageThree});
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 60px 120px 60px 40px;

    width: 589px;
    height: 800px;
  }
`;

const VipInfo = (): JSX.Element => {
  return (
    <div className="ctn mt-10 md:mt-24">
      <p className="text-center text-darkGrey tracking-[3px] uppecase mb-8 sm:mb-10">CRÉDITO SIN INTERESES</p>
      <p className="text-center font-light tracking-[-0.44px] text-[42px] leading-[50px] md:text-[60px] md:leading-[72px]">
        Descubre todos los <br />
        beneficios de <span className="text-darkBlue font-medium">Nelo VIP</span>
      </p>
      <div className="relative mt-12 md:mt-32 lg:h-[880px]">
        <StyledBoxTwo>
          <div className="z-10">
            <p className="text-white !text-left">NELO VIP</p>
            <p className="mt-6 text-white text-[20px] xl:text-[24px] tracking-[-0.24px] leading-[34px] font-light">
              Compras sin intereses, comercios exclusivos, atención prioritaria y muchos beneficios más.{' '}
              <a href="/vip" className="underline hover:opacity-80">
                Conoce Nelo VIP.
              </a>
            </p>
            <a
              className="bg-white text-primary rounded-[34px] py-4 px-8 mt-8 block w-fit"
              href="https://registrarse.nelo.mx/signup/phone-email"
            >
              CREA TU CUENTA
            </a>
          </div>
        </StyledBoxTwo>
        <StyledBoxOne>
          <div className="z-10">
            <h3 className="text-white !text-left">TARJETA FÍSICA VIP</h3>
            <p className="mt-6 text-white text-[24px] tracking-[-0.24px] leading-[34px] font-light">
              Disponible muy pronto para nuestros mejores usuarios VIP. <br />
              <a href="/vip" className="underline hover:opacity-80">
                Aprende más.
              </a>
            </p>
            <a
              className="bg-primary rounded-[34px] text-white py-4 px-8 mt-8 block w-fit"
              href="https://registrarse.nelo.mx/signup/phone-email"
            >
              CREA TU CUENTA
            </a>
          </div>
        </StyledBoxOne>
      </div>
    </div>
  );
};

export default VipInfo;
