import { all, SagaGenerator } from 'typed-redux-saga';
import { createAction, createReducer, PayloadAction } from 'typesafe-actions';
import { v4 as uuidv4 } from 'uuid';

export interface ApplicationError {
  errorMessage: string;
}

export const logout = createAction('application/LOGOUT')();
export const resetLoadingState = createAction('application/RESET_LOADING_STATE')<boolean>();
export const clearErrorMessage = createAction('application/CLEAR_ERROR_MESSAGES')();
export const updateErrorMessage = createAction('application/UPDATE_ERROR_MESSAGE')<ApplicationError>();

export const updateBlockingNetworkRequest = createAction('application/UPDATE_NETWORK_REQUEST')<boolean>();

export interface ApplicationProps {
  isLoading: boolean;
}

export interface ApplicationState {
  errorMessage: string;
  isLoading: boolean;
  sessionId: string;
}

export const initialState: ApplicationState = {
  errorMessage: '',
  isLoading: false,
  sessionId: uuidv4()
};

export const getState = ({ application }: { application: ApplicationState }): ApplicationState => application;

const setError = (state: ApplicationState, action: PayloadAction<string, ApplicationError>): ApplicationState => ({
  ...state,
  errorMessage: action.payload.errorMessage
});

const clearError = (state: ApplicationState): ApplicationState => ({
  ...state,
  errorMessage: ''
});

const setLoading = (state: ApplicationState, action: PayloadAction<string, boolean>): ApplicationState => ({
  ...state,
  isLoading: action.payload
});

export function* applicationSaga(): SagaGenerator<void> {
  yield* all([]);
}

export default createReducer(initialState)
  .handleAction(updateBlockingNetworkRequest, setLoading)
  .handleAction(resetLoadingState, setLoading)
  .handleAction(updateErrorMessage, setError)
  .handleAction(clearErrorMessage, clearError);
