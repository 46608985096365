import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ReferralState } from '../interfaces/nelo-api/DeviceState';

const useTrackReferrer = (updateReferralData: (payload: ReferralState) => void, referralData: ReferralState): void => {
  const [searchParams] = useSearchParams();
  useEffect((): void => {
    const campaign = searchParams.get('utm_campaign') || null;
    const source = searchParams.get('utm_source') || null;
    const medium = searchParams.get('utm_medium') || null;
    const term = searchParams.get('utm_term') || searchParams.get('term') || null;
    const ad = searchParams.get('utm_ad') || null;
    const productId = searchParams.get('productId') || null;
    const variantId = searchParams.get('variantId') || null;
    const gclid = searchParams.get('gclid') || null;
    const url = window.location.href;
    const isMarketplace = searchParams.get('source') === 'marketplace';

    // update referral data if included in the url, or if has not been previously set
    if (gclid || campaign || source || medium || term || ad || productId || variantId || referralData?.url === null) {
      const payload = { campaign, source, medium, term, ad, url, isMarketplace, productId, variantId, gclid };
      console.log('useTrackReferrer', payload);
      updateReferralData(payload);
    }
  }, [location]);
};

export default useTrackReferrer;
