import '../localization/i18n';

import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { AnalyticEvent } from '../analytics/AnalyticEvent';
import { emitAnalyticEventAction } from '../ducks/analytics';
import { ApplicationProps, ApplicationState, clearErrorMessage } from '../ducks/application';
import { AuthState } from '../ducks/auth';
import { SignupState, updatePin } from '../ducks/signup';
import { submitSignup } from '../ducks/signup';
import { AuthAction } from '../interfaces/nelo-api/AuthAction';
import validate from '../util/inputs';
import Nelo from './Nelo';
import { PinField } from './PinEntry';

interface DispatchProps {
  submitSignup: () => void;
  updatePin: (pin: string) => void;
  emitEvent: (props: AnalyticEvent) => void;
}

interface StateProps extends ApplicationProps {
  pin: string | null;
  errorMessage: string;
  authAction: AuthAction | null;
}

function PinEntry(props: DispatchProps & StateProps): ReactElement {
  const classes = Nelo.useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [verifyPin, setVerifyPin] = useState('');
  const [valid, setValid] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [localErrorMessage, setLocalErrorMessage] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (props.authAction === 'API') {
      props.emitEvent({ name: 'WEB_SIGNUP_REQUESTED' });
      setLoading(false);
      navigate('/signup/completed');
    }
  }, [props.authAction]);

  useEffect(() => {
    if (verifyPin.length >= 4 && validate.pin(verifyPin) && props?.pin === verifyPin) {
      setLocalErrorMessage(undefined);
      setValid(true);
    }
    if (verifyPin.length >= 4 && validate.pin(verifyPin) && props?.pin !== verifyPin) {
      setLocalErrorMessage(t('signup.pinVerify.pinMismatch'));
      setValid(false);
    }
  }, [verifyPin]);

  return (
    <Nelo.BrandWrapper
      title={t('signup.pinVerify.title')}
      subtitle={t('signup.pinVerify.subtitle')}
      progress={6}
      localErrorMessage={localErrorMessage}
    >
      <PinField className={classes.textEntry} pin={verifyPin} updatePin={setVerifyPin} label={''} />
      <Nelo.Button
        disabled={!valid}
        isLoading={(!localErrorMessage || !props.errorMessage) && loading}
        text={t('signup.pinVerify.buttonText')}
        onClick={(): void => {
          if (valid) {
            props.emitEvent({ name: 'WEB_SIGNUP_PIN_VERIFY', params: { verify: 'true' } });
            clearErrorMessage();
            setLoading(true);
            props.submitSignup();
          }
        }}
        valid={valid}
      />
    </Nelo.BrandWrapper>
  );
}

const mapDispatchToProps = {
  submitSignup: (): any => submitSignup(),
  updatePin: (pin: string): any => updatePin(pin),
  emitEvent: (props: AnalyticEvent): any => emitAnalyticEventAction(props)
};

const mapStateToProps = ({
  auth,
  signup,
  application
}: {
  auth: AuthState;
  signup: SignupState;
  application: ApplicationState;
}): StateProps => ({
  pin: signup.pin,
  isLoading: signup.isLoading,
  errorMessage: application.errorMessage,
  authAction: auth.authAction
});

export default connect(mapStateToProps, mapDispatchToProps)(PinEntry);
