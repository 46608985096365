const CreditBanner = (): JSX.Element => {
  return (
    <div className="my-8 sm:my-12 mx-4 sm:m-auto  bg-primary rounded-3xl m-auto max-w-[1200px] h-[580px] sm:h-[800px] grid place-items-center">
      <div className="text-white text-[42px] sm:text-[60px] font-manrope text-center tracking-[-0.44px]">
        <p>Crédito</p>
        <p className={`font-light opacity-50`}>a tu medida</p>
        <p className={`font-light opacity-50`}>justo y transparente</p>
        <p className={`font-light opacity-50`}>a tu alcance</p>
        <p>como debe ser</p>
      </div>
    </div>
  );
};

export default CreditBanner;
