import styled from 'styled-components';

const StyledBackgrounds = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 40px;
  padding: 2.5rem;
`;

interface CardI {
  title: string;
  source: string;
  description: string;
  color: 'green' | 'rose' | 'blue' | 'purple';
  componentClass?: string;
  imgWidth: number;
  imgHeight: number;
}

const Card = ({ title, source, description, componentClass, color, imgWidth, imgHeight }: CardI): JSX.Element => {
  const background = {
    green: 'green_background',
    rose: 'rose_background',
    blue: 'blue_background',
    purple: 'purple_background'
  };

  const backgroundColor = background[color];

  return (
    <StyledBackgrounds className={`${componentClass} ${backgroundColor}`}>
      <p className="font-manrope text-darkGrey text-[24px] lg:text-[28px] font-medium tracking-[-0.56px]">{title}</p>
      <div className="min-h-[188px] my-4 flex items-center">
        <img width={imgWidth} height={imgHeight} alt="" src={source} />
      </div>
      <div>
        <p className="text-darkGrey tracking-[-0.24px] font-light leading-[30px] xl:leading-[34px] text-[20px] xl:text-[22px] mt-3 xl:mt-4">
          {description}
        </p>
      </div>
    </StyledBackgrounds>
  );
};

export default Card;
