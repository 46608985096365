import '@fontsource/manrope';
import '@fontsource/nunito-sans';

import { Box, Grid } from '@material-ui/core';
import {
  makeStyles,
  ThemeProvider,
  unstable_createMuiStrictModeTheme as createMuiTheme
} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { css } from 'styled-components';
import { EmptyAction, PayloadAction } from 'typesafe-actions';

import { trackCopyPaste, trackPageview } from '../../ducks/analytics';
import { ApplicationState, clearErrorMessage } from '../../ducks/application';
import { resetLoadingState } from '../../ducks/application';
import useHandleCopyPaste from '../../hooks/useHandleCopyPaste';
import useHandlePageview from '../../hooks/useHandlePageview';
import { colors, spacing } from '../../styling';
import NeloBrandHeader from './NeloBrandHeader';
import NeloProgressBar from './NeloProgressBar';

export const theme = createMuiTheme({
  components: {
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true
      }
    }
  },
  typography: {
    fontFamily: ['Manrope', 'sans-serif'].join(',')
  },
  palette: {
    primary: {
      main: colors.primary
    },
    secondary: {
      main: colors.secondary
    },
    success: {
      main: colors.neloGreen
    }
  }
});

const load = css`
  0% {
    mask-position: 0% 0%, 50% 0%, 100% 0%;
  } /* all of them at the top */
  16.67% {
    mask-position: 0% 100%, 50% 0%, 100% 0%;
  }
  33.33% {
    mask-position: 0% 100%, 50% 100%, 100% 0%;
  }
  50% {
    mask-position: 0% 100%, 50% 100%, 100% 100%;
  } /* all of them at the bottom */
  66.67% {
    mask-position: 0% 0%, 50% 100%, 100% 100%;
  }
  83.33% {
    mask-position: 0% 0%, 50% 0%, 100% 100%;
  }
  100% {
    mask-position: 0% 0%, 50% 0%, 100% 0%;
  } /* all of them at the top */
`;

export const useStyles = makeStyles({
  progressContainer: {},
  hiddenBackIcon: {
    position: 'absolute',
    visibility: 'hidden',
    marginTop: -18,
    marginLeft: -16,
    minWidth: 20
  },
  backButton: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  backIcon: {
    position: 'absolute',
    color: colors.neloBlue,
    marginTop: -10,
    marginLeft: -16,
    minWidth: 20,
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  progressBarWithArrow: {
    width: '90%',
    minWidth: 320,
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: 0,
    [theme.breakpoints.down('md')]: {
      maxWidth: '100vw',
      width: '100%',
      minWidth: '100vw',
      paddingTop: 0,
      paddingBottom: spacing.spacing4x,
      marginTop: spacing.spacing2x
    }
  },
  progressBar: {
    width: '90%',
    minWidth: 320,
    marginRight: 'auto',
    marginLeft: 'auto',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100vw',
      width: '100%',
      minWidth: '100vw',
      paddingTop: 0,
      paddingBottom: spacing.spacing4x,
      marginTop: spacing.spacing2x
    }
  },
  loader: {
    width: 180,
    aspectRation: '8/5',
    animation: `${load} 2s infinite`
  },
  contentText: {
    paddingLeft: 45,
    paddingRight: 45,
    [theme.breakpoints.down('md')]: {
      paddingTop: 0,
      paddingLeft: 21,
      paddingRight: 21
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
      paddingLeft: 21,
      paddingRight: 21
    }
  },
  error: {
    color: '#FF4344',
    fontWeight: 600,
    fontSize: spacing.spacing2x,
    marginBottom: spacing.spacing2x
  },
  adornmentIcon: {
    display: 'grid',
    justifyContent: 'center',
    alignItems: 'center'
  },
  formControl: {
    marginTop: spacing.spacing1x,
    marginBottom: spacing.spacing1x,
    minWidth: 120,
    [theme.breakpoints.down('md')]: {
      marginTop: spacing.spacingHalfx,
      marginBottom: spacing.spacingHalfx
    }
  },
  smallTextEntry: {
    width: '48%'
  },
  addressTextEntry: {
    border: 'none !important'
  },
  textEntry: {
    marginBottom: spacing.spacing2x,
    background: colors.white,
    border: `1px solid rgba(170, 170, 170, 0.25)`,
    borderShadow: 'none',
    borderRadius: spacing.spacing1x,
    '& .MuiInput-underline:after': {
      border: `3px solid ${colors.neloBlue}`
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'none'
      },
      '&:hover fieldset': {
        border: `2.5px solid ${colors.neloBlue}`
      },
      '&.Mui-focused fieldset': {
        border: `2.5px solid ${colors.neloBlue}`
      }
    }
  },
  note: {
    marginTop: spacing.spacing2x,
    color: colors.secondary
  },
  bannerFooter: {
    marginTop: spacing.spacing3x,
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  bannerFooterLinks: {
    padding: spacing.spacing1x
  },
  bannerFooterContainer: {
    justifyContent: 'center'
  },
  bannerContainer: {
    position: 'sticky',
    top: 0,
    paddingTop: spacing.spacing2x,
    paddingBottom: spacing.spacing2x,
    textAlign: 'center',
    backgroundColor: 'white',
    [theme.breakpoints.down('md')]: {
      paddingBottom: spacing.spacing1x,
      paddingTop: spacing.spacing1x
    }
  },
  mainContainer: {
    backgroundColor: colors.neloBlue,
    minHeight: '100vh',
    paddingBottom: spacing.spacing4x,
    [theme.breakpoints.down('md')]: {
      backgroundColor: 'white'
    }
  },
  mainGrid: {
    marginTop: spacing.spacing4x,
    [theme.breakpoints.down('md')]: {
      marginTop: 0
    }
  },
  contentContainer: {
    minHeight: 440,
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 580,
    marginBottom: spacing.spacing2x,
    marginTop: spacing.spacing4x,
    borderRadius: 40,
    boxShadow: `0px 4px 16px rgba(0, 0, 0, 0.16)`,
    padding: spacing.spacing4x,
    backgroundColor: '#FFFFFF',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      marginTop: 0,
      marginBottom: spacing.spacing4x,
      paddingBottom: spacing.spacing3x,
      paddingTop: 0,
      paddingLeft: 0,
      paddingRight: 0,
      borderRadius: 0,
      boxShadow: 'none',
      alignItems: 'start',
      justifyContent: 'flex-start'
    }
  },
  loadingIcon: {
    marginBottom: spacing.spacing1x,
    marginTop: 0
  },
  icon: {
    display: 'inline-block',
    marginBottom: spacing.spacing2x,
    height: 40,
    [theme.breakpoints.down('sm')]: {
      marginBottom: spacing.spacing1x,
      height: 30
    }
  },
  title: {
    fontWeight: 800,
    fontFamily: 'Manrope',
    fontSize: spacing.spacing6x,
    alignItems: 'center',
    color: colors.neloBlue,
    marginBottom: spacing.spacing2x,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      fontSize: spacing.spacing4x,
      marginBottom: spacing.spacing3x
    }
  },
  subtitle: {
    marginBottom: spacing.spacing2x,
    fontFamily: 'Nunito Sans',
    fontSize: 18,
    color: colors.secondaryAccent
  },
  description: {
    marginTop: spacing.spacing4x,
    fontFamily: 'Nunito Sans',
    fontSize: spacing.spacing2x,
    color: colors.secondaryAccent,
    [theme.breakpoints.down('sm')]: {
      marginBottom: spacing.spacing1x
    }
  }
});

interface StateProps {
  errorMessage: string;
}

interface DispatchProps {
  clearErrorMessage: () => void;
  trackCopyPaste: (fieldName: string, path: string) => void;
  setLoading: (value: boolean) => void;
  trackPageview: (path: string) => void;
}

interface NeloBrandWrapperProps {
  title?: string;
  subtitle?: string;
  description?: string;
  progress?: number;
  children?: ReactElement | ReactElement[];
  hideBackButton?: boolean;
  hideProgressBar?: boolean;
  onClick?: () => void;
  localErrorMessage?: string | undefined;
}

function NeloBrandWrapper(props: NeloBrandWrapperProps & StateProps & DispatchProps): ReactElement {
  const navigate = useNavigate();
  const { progress = 1, hideBackButton = false, hideProgressBar = false, localErrorMessage, onClick } = props;
  const classes = useStyles();
  useHandlePageview(props.clearErrorMessage, props.trackPageview);
  useHandleCopyPaste(window, props.trackCopyPaste);

  useEffect(() => {
    const listener = (e: KeyboardEvent): void => {
      if ((e.code === 'NumpadEnter' || e.code === 'Enter') && onClick) onClick();
    };
    document.addEventListener('keydown', listener);
    return (): void => {
      document.removeEventListener('keydown', listener);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box className={classes.mainContainer}>
        <NeloBrandHeader />
        <Grid className={classes.mainGrid} alignItems="flex-start">
          <Grid item lg={3} xl={4} />
          <Grid
            item
            md={12}
            lg={6}
            xl={4}
            container
            className={classes.contentContainer}
            direction="column"
            alignItems="flex-start"
          >
            <Grid container justifyContent="space-between" direction="row">
              <NeloProgressBar
                progress={progress}
                goBack={(): void => navigate(-1)}
                hideBackButton={hideBackButton}
                hideProgressBar={hideProgressBar}
              />
            </Grid>
            <Grid container className={classes.contentText}>
              <Typography variant="h4" className={classes.title}>
                {props.title}
              </Typography>
              <Typography className={classes.subtitle}>{props.subtitle}</Typography>
              <Grid container direction="column">
                {(localErrorMessage || props.errorMessage) && (
                  <Typography className={classes.error}>{localErrorMessage || props.errorMessage}</Typography>
                )}
                {props.children}
              </Grid>
              {props.description && <Typography className={classes.description}>{props.description}</Typography>}
            </Grid>
          </Grid>
          <Grid item lg={3} xl={4} />
        </Grid>
      </Box>
    </ThemeProvider>
  );
}

const mapStateToProps = ({ application }: { application: ApplicationState }): StateProps => ({
  errorMessage: application.errorMessage
});

const mapDispatchToProps = {
  clearErrorMessage: (): EmptyAction<string> => clearErrorMessage(),
  trackCopyPaste: (fieldName: string, path: string): EmptyAction<string> => trackCopyPaste({ fieldName, path }),
  setLoading: (value: boolean): PayloadAction<'application/RESET_LOADING_STATE', boolean> => resetLoadingState(value),
  trackPageview: (path: string): EmptyAction<string> => trackPageview(path)
};

export default connect(mapStateToProps, mapDispatchToProps)(NeloBrandWrapper);
