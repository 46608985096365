import { StrictEffect } from '@redux-saga/types';
import { call, put, SagaGenerator } from 'typed-redux-saga';
import { PayloadAction } from 'typesafe-actions';

import { updateBlockingNetworkRequest, updateErrorMessage } from '../ducks/application';
import { ApiError } from '../errors/NeloApi';

export function* doNeloApiRequestWithResponse<NeloResponse>(
  neloApiMethod: () => Promise<Response>
): Generator<StrictEffect, NeloResponse, void> {
  const response = yield* call(neloApiMethod);
  if (response.headers.get('Content-Type') === 'application/json') {
    const responseBody: NeloResponse = yield* call([response, 'json']);
    return responseBody;
  }
  throw Error('Expected response body');
}

export function* doNeloApiRequest(neloApiMethod: () => Promise<Response>): Generator<StrictEffect, void, void> {
  yield* call(neloApiMethod);
}

export function* safeCall(
  saga: (...args: PayloadAction<string, any>[]) => Generator<StrictEffect, void, void>,
  ...args: PayloadAction<string, string>[]
): SagaGenerator<void> {
  try {
    yield* put(updateBlockingNetworkRequest(true));
    yield* call(saga, ...args);
  } catch (error) {
    if (error instanceof ApiError) {
      yield* put(
        updateErrorMessage({
          errorMessage: error.getMessage()
        })
      );
    } else {
      throw error;
    }
  } finally {
    yield* put(updateBlockingNetworkRequest(false));
  }
}
