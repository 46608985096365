const EasyCredit = (): JSX.Element => {
  return (
    <div className="ctn mt-20 mb-12 md:mb-16 sm:mt-40">
      <h2 className="text-center text-darkGrey tracking-[3px] uppecase mb-16 sm:mb-24">
        CRÉDITO AL ALCANCE DE TU MANO
      </h2>
      <div className="br m-auto">
        <p className="text-center font-light tracking-[-0.44px] text-[42px] leading-[50px] md:text-[60px] md:leading-[72px]">
          ¡Disfruta de tu crédito <br />
          <span className="font-normal text-pink">en minutos</span> y empieza a<br /> comprar en tus tiendas <br />
          favoritas!
        </p>
      </div>
    </div>
  );
};

export default EasyCredit;
