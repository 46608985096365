interface Coordinates {
  latitude: number;
  longitude: number;
}

interface Position {
  coords: Coordinates;
  timestamp: number;
}

export function getPosition(options?: PositionOptions): Promise<Position> {
  return new Promise((resolve, reject) => navigator.geolocation.getCurrentPosition(resolve, reject, options));
}
