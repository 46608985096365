import BannerImage from '../../Nelo/img/bannerGroupOne.png';
import BannerSecondImage from '../../Nelo/img/bannerGroupTwo.png';
import InputBanner from '../InputBanner';

const Banner = (): JSX.Element => {
  return (
    <div className="h-[1080px] lg:h-[980px] mr-10 ml-6 lg:ml-12 2xl:max-w-[1300px] 2xl:mx-auto pt-[100px] lg:pt-[130px] flex flex-col justify-between items-center lg:items-start lg:flex-row">
      <div className="text-white flex justify-center">
        <div className="mt-8 sm:ml-8 2xl:ml-0 lg:mt-28 lg:max-xl:max-w-[470px]">
          <h1 className="br manrope font-light text-[52px] lg:text-[73px] leading-[60px] lg:leading-[88px] tracking-[-2.369px]">
            Crédito <span className="font-normal text-citron">a tu medida</span>,<br /> como debe ser
          </h1>
          <p className="text-[18px] lg:text-[28px] max-w-[432px] mt-6 font-light">
            Completa tu registro y obtén tu crédito Nelo en tan solo 5 minutos.
          </p>
          <InputBanner />
        </div>
      </div>
      <div className="relative h-full hidden lg:block">
        <img
          className="absolute bottom-20 right-40 lg:max-xl:right-20"
          alt=""
          width={340}
          height={759}
          src={BannerImage}
        />
        <img alt="" width={340} height={759} src={BannerSecondImage} />
      </div>
      <div className="relative mt-12 flex justify-center lg:hidden">
        <img className="mt-16 z-10" alt="" width={213} height={540} src={BannerImage} />
        <img className="-ml-24 z-0" alt="" width={213} height={540} src={BannerSecondImage} />
      </div>
    </div>
  );
};

export default Banner;
