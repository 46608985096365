const Footer = (): JSX.Element => {
  return (
    <footer className="pt-20 pb-24 px-8 md:px-32 bg-primary">
      <p className="text-white">
        © 2023 Nelo Mobile, S.A. de C.V., se encuentra regulado por la Procuraduría Federal de Consumidor y sujeto a la
        supervisión de la Secretaría de Hacienda y Crédito Público a través del Servicio de Administración Tributaría,
        para efectos de lo dispuesto por el artículo 17 fracción IV de la Ley Federal para la Prevención e
        Identificación de Operaciones con Recursos de Procedencia Ilícita.
      </p>
    </footer>
  );
};

export default Footer;
