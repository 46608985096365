import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './en.json';
import es from './es.json';

export const resources = {
  en: {
    translation: {
      ...en
    }
  },
  es: {
    translation: {
      ...es
    }
  }
} as const;

i18next.use(initReactI18next).init({
  lng: navigator.language.split('-')[0],
  resources
});

export default i18next;
