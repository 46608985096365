import { StrictEffect } from '@redux-saga/types';
import { all, call, put, SagaGenerator, select, takeEvery } from 'typed-redux-saga';
import { createAction, createReducer, PayloadAction } from 'typesafe-actions';

import NeloApiClient from '../clients/NeloApiClient';
import { AuthAction } from '../interfaces/nelo-api/AuthAction';
import { logout } from './application';

export const updateAuthState = createAction('auth/UPDATE_AUTH_STATE')<AuthCredentials>();
export const updatePin = createAction('auth/UPDATE_PIN_CODE')<string>();
export const resetAuth = createAction('auth/RESET_AUTH')();

export interface AuthCredentials {
  accessToken: string;
  authAction: AuthAction;
  userUuid: string;
}

export interface AuthState {
  accessToken: string | null;
  authAction: AuthAction | null;
  pin: string;
  userUuid: string;
  isVerified: boolean;
}

export const initialState: AuthState = {
  accessToken: null,
  authAction: null,
  userUuid: '',
  pin: '',
  isVerified: false
};

export const getState = ({ auth }: { auth: AuthState }): AuthState => auth;

const doLogout = (state: AuthState): AuthState => ({
  ...state,
  accessToken: null,
  authAction: null,
  userUuid: '',
  pin: ''
});

const setCredentials = (state: AuthState, action: PayloadAction<string, AuthCredentials>): AuthState => ({
  ...state,
  accessToken: action.payload.accessToken,
  authAction: action.payload.authAction,
  userUuid: action.payload.userUuid,
  pin: ''
});

const setPin = (state: AuthState, action: PayloadAction<string, string>): AuthState => ({
  ...state,
  pin: action.payload
});

const resetAuthAction = (state: AuthState): AuthState => ({
  ...state,
  authAction: null
});

export default createReducer(initialState)
  .handleAction(updateAuthState, setCredentials)
  .handleAction(resetAuth, resetAuthAction)
  .handleAction(logout, doLogout)
  .handleAction(updatePin, setPin);
