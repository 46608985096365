import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Slider from '@material-ui/core/Slider';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { EmptyAction } from 'typesafe-actions';

import { ApplicationState, clearErrorMessage } from '../../ducks/application';
import { colors } from '../../styling';
import Nelo from './index';

interface NeloProgressProps {
  progress?: number;
  hideBackButton?: boolean;
  hideProgressBar?: boolean;
  goBack?: () => void;
}

interface StateProps {
  errorMessage: string;
}

interface DispatchProps {
  clearErrorMessage: () => void;
}

function NeloProgressBar(props: StateProps & DispatchProps & NeloProgressProps): ReactElement {
  const classes = Nelo.useStyles();
  const { progress = 1, hideBackButton = false, hideProgressBar = false } = props;
  const totalSteps = 7;

  return (
    <Grid item sm={2} md={12} className={classes.progressContainer}>
      <IconButton
        className={classes.backButton}
        disableRipple
        disableFocusRipple
        sx={{
          ml: 1,
          '&.MuiButtonBase-root:hover': {
            bgcolor: 'transparent'
          },
          padding: '0 12px 12px 12px'
        }}
        onClick={(): void => {
          clearErrorMessage();
          props.goBack && props.goBack();
        }}
      >
        <ArrowBackIcon className={!hideBackButton ? classes.backIcon : classes.hiddenBackIcon} />
      </IconButton>
      {hideProgressBar ? (
        <></>
      ) : (
        <Slider
          disabled
          className={hideBackButton ? classes.progressBarWithArrow : classes.progressBar}
          sx={{
            '& .css-jccrfp-MuiSlider-track': {
              color: colors.neloBlue
            },
            '& .Mui-disabled': {
              color: colors.neloBlue
            },
            '& .css-ebcf4f-MuiSlider-rail': {
              backgroundColor: '#D9D9D9'
            }
          }}
          defaultValue={Math.floor((progress / totalSteps) * 100)}
        />
      )}
    </Grid>
  );
}

const mapStateToProps = ({ application }: { application: ApplicationState }): StateProps => ({
  errorMessage: application.errorMessage
});

const mapDispatchToProps = {
  clearErrorMessage: (): EmptyAction<string> => clearErrorMessage()
};

export default connect(mapStateToProps, mapDispatchToProps)(NeloProgressBar);
