import '../localization/i18n';

import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { AnalyticEvent } from '../analytics/AnalyticEvent';
import { emitAnalyticEventAction } from '../ducks/analytics';
import { ApplicationProps, ApplicationState, clearErrorMessage } from '../ducks/application';
import { SignupState, updatePin } from '../ducks/signup';
import validate from '../util/inputs';
import Nelo from './Nelo';

interface DispatchProps {
  updatePin: (pin: string) => void;
  emitEvent: (props: AnalyticEvent) => void;
}

interface StateProps extends ApplicationProps {
  pin: string | null;
}

interface PinFieldProps {
  label: string;
  className: string;
  pin: string | null;
  updatePin: (pin: string) => void;
  maxLength?: number;
}

export const PinField = ({ className, pin, updatePin, maxLength = 4, label }: PinFieldProps): ReactElement => {
  return (
    <Nelo.TextField
      required
      autoFocus
      name="Pin"
      id="standard-basic"
      className={className}
      setValue={updatePin}
      label={label}
      onChange={(e: React.ChangeEvent<HTMLInputElement>): void => updatePin(e.target.value)}
      type="password"
      autocomplete="off"
      value={pin?.toString() || ''}
      placeholder={'* * * *'}
      inputProps={{
        maxLength
      }}
      valid={pin?.length === 4}
    />
  );
};

function PinEntry(props: DispatchProps & StateProps): ReactElement {
  const classes = Nelo.useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [valid, setValid] = useState<boolean>(false);

  useEffect(() => {
    if (props?.pin && props.pin.length >= 4 && validate.pin(props.pin)) {
      setValid(true);
    }
  }, [props.pin]);

  return (
    <Nelo.BrandWrapper title={t('signup.pin.title')} subtitle={t('signup.pin.subtitle')} progress={6}>
      <PinField className={classes.textEntry} pin={props.pin} updatePin={props.updatePin} label={''} />
      <Nelo.Button
        disabled={!valid}
        isLoading={props.isLoading}
        text={t('signup.pin.buttonText')}
        onClick={(): void => {
          clearErrorMessage();
          props.emitEvent({ name: 'WEB_SIGNUP_PIN_ENTRY', params: { verify: 'true' } });
          navigate('/signup/pin-verify');
        }}
        valid={valid}
      />
    </Nelo.BrandWrapper>
  );
}

const mapDispatchToProps = {
  updatePin: (pin: string): any => updatePin(pin),
  emitEvent: (props: AnalyticEvent): any => emitAnalyticEventAction(props)
};

const mapStateToProps = ({
  signup,
  application
}: {
  signup: SignupState;
  application: ApplicationState;
}): StateProps => ({
  pin: signup.pin,
  isLoading: application.isLoading && !application.errorMessage
});

export default connect(mapStateToProps, mapDispatchToProps)(PinEntry);
