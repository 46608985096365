import styled from 'styled-components';

import ControlButton from './ControlButton';
import { getImageStarts } from './getImageStarts';

const Container = styled.div`
  position: relative;
  border-radius: 40px;
  padding: 68px 24px;
  width: auto;
  min-height: 460px;
  height: auto;

  @media screen and (min-width: 768px) {
    padding: 68px 144px;
  }
`;

interface TestimonyCardI {
  testimony: string;
  author: string;
  className?: string;
  color: 'blue' | 'rose' | 'green' | 'darkBlue' | 'golden';
}

const TestimonyCard = ({ className, color, testimony, author }: TestimonyCardI): JSX.Element => {
  const imageSource = getImageStarts(color);

  return (
    <Container className={className}>
      <div className="grid place-items-center mb-16">
        <img alt="" src={imageSource} width={180} height={35} />
      </div>
      <ControlButton className="absolute top-[45%] left-10" />
      <div
        className={`text-center font-nunito font-light lg:leading-[40px] tracking-[-0.56px] text-[18px] lg:text-[28px] ${
          color === 'darkBlue' ? 'text-white' : 'text-darkGrey'
        }`}
      >
        <p className="text-center">{testimony}</p>
        <p className="mt-14 tracking-[3px] font-medium text-[16px] lg:text-[20px]">{author}</p>
      </div>
      <ControlButton className="absolute top-[45%] right-10" right />
    </Container>
  );
};

export default TestimonyCard;
