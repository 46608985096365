import BlueImage from '../../Nelo/img/GroupStartsBlue.png';
import greenImage from '../../Nelo/img/GroupStartsGreen.png';
import roseImage from '../../Nelo/img/GroupStartsRose.png';

export const getImageStarts = (color: string): string => {
  switch (color) {
    case 'rose':
      return roseImage;
    case 'green':
      return greenImage;
    case 'blue':
      return BlueImage;
    default:
      return '/images/GroupStartsRose.png';
  }
};
