import '@fontsource/manrope';
import '@fontsource/nunito-sans';

import Grid from '@material-ui/core/Grid';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { EmptyAction } from 'typesafe-actions';

import { trackPageview } from '../../ducks/analytics';
import { clearErrorMessage } from '../../ducks/application';
import useHandlePageview from '../../hooks/useHandlePageview';
import { colors, spacing } from '../../styling';
import Nelo from './';
import CardAndPhone from './img/cardAndPhone.png';
import GradientImage from './img/gradient.jpg';
import { theme } from './NeloBrandWrapper';

export const useStyles = makeStyles({
  pageContainer: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
  },
  mainContainer: {
    flexGrow: 1,
    overflow: 'hidden !important',
    paddingBottom: '64px',
    display: 'flex',
    flexDirection: 'column'
  },
  approvedBackgroundImage: {
    background: `url('${GradientImage}')`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed'
  },
  contentContainer: {
    marginLeft: 200,
    marginTop: spacing.spacing2x,
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
      marginLeft: 0
    },
    flexGrow: 1
  },
  backgroundColor: {
    backgroundColor: colors.neloBlue
  },
  landingTitle: {
    fontSize: spacing.spacing9x,
    fontWeight: 800
  },
  approvedTitle: {
    marginTop: `80px !important`,
    maxWidth: `900px !important`,
    [theme.breakpoints.down('md')]: {
      maxWidth: '95%',
      marginTop: 'inherit'
    }
  },
  title: {
    maxWidth: 800,
    marginTop: spacing.spacing4x,
    marginBottom: spacing.spacing4x,
    fontFamily: 'Manrope',
    fontWeight: 800,
    fontSize: 72,
    alignItems: 'center',
    color: colors.white,
    [theme.breakpoints.down('lg')]: {
      fontSize: 64,
      maxWidth: 600
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: spacing.spacing2x,
      marginRight: spacing.spacing2x,
      marginTop: spacing.spacing4x,
      marginBottom: 0,
      fontSize: 48,
      lineHeight: 1.2
    }
  },
  rejectedText: {
    color: colors.white
  },
  subtitle: {
    maxWidth: 900,
    marginTop: 80,
    fontFamily: 'Nunito Sans',
    fontSize: 24,
    color: colors.white,
    [theme.breakpoints.down('lg')]: {
      fontSize: 20,
      maxWidth: 700
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '95%',
      marginLeft: 'auto',
      marginRight: 'auto',
      textAlign: 'center',
      marginBottom: spacing.spacing2x,
      fontSize: spacing.spacing2x,
      marginTop: 40
    }
  },
  heroImage: {
    maxHeight: '60vh',
    maxWidth: '30vw',
    marginRight: spacing.spacing2x,
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }
});

interface NeloBrandWrapperProps {
  title: string;
  subtitle: string | null;
  children?: ReactElement | ReactElement[];
  onClick?: () => void;
  footerColor?: string;
}

interface DispatchProps {
  clearErrorMessage: () => void;
  trackPageview: (path: string) => void;
}

function NeloBrandCompletionPage(props: NeloBrandWrapperProps & DispatchProps): ReactElement {
  const classes = useStyles();
  const { subtitle } = props;
  useHandlePageview(props.clearErrorMessage, props.trackPageview);

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.pageContainer}>
        <Nelo.BrandHeader />
        <Grid className={`${classes.approvedBackgroundImage} ${classes.mainContainer}`} container>
          <Grid item container className={classes.contentContainer} direction="row">
            <Grid item md={6}>
              {subtitle && <Typography className={classes.subtitle}>{subtitle}</Typography>}
              <Typography variant="h4" className={classes.title}>
                <div dangerouslySetInnerHTML={{ __html: props.title.toString() }} />
              </Typography>
              <Grid container direction="column">
                {props.children}
              </Grid>
            </Grid>
            <Grid item md={6} alignContent="center">
              <img className={classes.heroImage} src={CardAndPhone} />
            </Grid>
          </Grid>
          <Nelo.BrandFooter />
        </Grid>
      </div>
    </ThemeProvider>
  );
}

const mapDispatchToProps = {
  clearErrorMessage: (): EmptyAction<string> => clearErrorMessage(),
  trackPageview: (path: string): EmptyAction<string> => trackPageview(path)
};

export default connect(null, mapDispatchToProps)(NeloBrandCompletionPage);
