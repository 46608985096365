export const MexicanStates = [
  'Aguascalientes',
  'Baja California',
  'Baja California Sur',
  'Campeche',
  'Coahuila de Zaragoza',
  'Colima',
  'Chiapas',
  'Chihuahua',
  'Ciudad de México',
  'Distrito Federal',
  'Durango',
  'Guanajuato',
  'Guerrero',
  'Hidalgo',
  'Jalisco',
  'Estado de México',
  'Michoacán de Ocampo',
  'Morelos',
  'Nayarit',
  'Nuevo León',
  'Oaxaca',
  'Puebla',
  'Querétaro',
  'Quintana Roo',
  'San Luis Potosí',
  'Sinaloa',
  'Sonora',
  'Tabasco',
  'Tamaulipas',
  'Tlaxcala',
  'Veracruz de Ignacio de la Llave',
  'Yucatán',
  'Zacatecas'
] as const;
export type MexicanState = typeof MexicanStates[number];

export const isMexicanState = (x: MexicanState | undefined | null): x is MexicanState =>
  x !== undefined && x !== null && MexicanStates.includes(x);
