import { useEffect, useState } from 'react';
import styled from 'styled-components';

import LogoIcon from '../Logo';

const StyledHeader = styled.header<{ light: boolean }>`
  box-shadow: ${({ light }): string => (light ? '0px 5px 8px rgba(201, 199, 199, 0.25)' : 'none')};
`;

const Header = (): JSX.Element => {
  const [light, setLight] = useState(false);
  const initialNavColor = light ? `bg-white` : 'bg-transparent';
  const [navColor, setnavColor] = useState(initialNavColor);

  const textColor = light ? 'text-primary' : 'text-white';

  const listenScrollEvent = (): void => {
    if (window.scrollY > 8) {
      setnavColor(`bg-white`);
      setLight(true);
    } else {
      setnavColor(`bg-transparent`);
      setLight(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent);
    return () => {
      window.removeEventListener('scroll', listenScrollEvent);
    };
  }, []);

  return (
    <StyledHeader
      light={light}
      className={`${navColor} fixed z-50 top-0 left-0 right-0 transition-colors duration-300`}
    >
      <div
        className={`mr-6 ml-6 lg:mr-10 sm:ml-12 lg:ml-20 2xl:max-w-[1300px] 2xl:mx-auto ${textColor} flex items-center justify-between`}
      >
        <div className="flex items-center text-lg lg:h-24">
          <a href="/">
            <LogoIcon lightHeader={light} />
          </a>
        </div>
        <a
          className="bg-primary transition-colors hover:bg-citron hover:text-primary text-white my-4 md:my-0 py-2 md:py-[14px] px-[28px] rounded-[34px] text-base md:text-[20px] font-semibold uppercase"
          href="/signup/phone-email"
        >
          Crea tu cuenta
        </a>
      </div>
    </StyledHeader>
  );
};

export default Header;
